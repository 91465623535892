import * as venType from "../../constant/vendorConstant/vendorConstants";

export const getVendorsReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendors: action.payload,
      };
    case venType.GET_VENDORS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorprofileReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case venType.GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_PROFILE_SUCCESS:
      return {
        ...state,
        vendorprofile: action.payload,
        loading: false,
        success: true,
      };
    case venType.GET_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorprofilePerUserReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case venType.GET_PROFILE_PER_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_PROFILE_PER_USER_SUCCESS:
      return {
        ...state,
        vendorProfilePerUser: action.payload,
        loading: false,
        success: true,
      };
    case venType.GET_PROFILE_PER_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        uProfile: action.payload,
      };
    case venType.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getViewInvReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VIEWINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VIEWINV_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        viewinv: action.payload,
      };
    case venType.GET_VIEWINV_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORSEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORSEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        search: action.payload,
      };
    case venType.GET_VENDORSEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getTotalInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORSTATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORSTATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        totalstatus: action.payload,
      };
    case venType.GET_VENDORSTATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAmountVendorStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case venType.GET_VENDORAMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case venType.GET_VENDORAMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        amtvendorstatus: action.payload,
      };
    case venType.GET_VENDORAMOUNT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
