import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../components/LoginPass.css";
import '../components/Login.css';
import { addForgotPassAction } from "../action/userAction";

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [input, setInput] = useState("");
  const [error, setError] = useState()
  const history = useHistory();
  const handlePassword = (e) => {
    e.preventDefault()
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(input)) {
      setError('Please enter valid email id')
    } else {
      const email = { email: input };
      dispatch(addForgotPassAction(email,history));

      //history.push("");
      // history.push("/submitotp");
      // history.push("/passwordChange");
    }
  };
  useEffect(()=>{

  },[])
  return (
    <div className="background-img">
      <div className="forgot-outer">
        <div className="login-1">
          <div className="imagecol">
            <img
              src="https://nimapinfotech.com/wp-content/uploads/2019/01/nimap-logo.png"
              alt="" className="nimap-logo"
            />
          </div>
          <div className="invoicelabel">
            <label className="projectName">Invoice Management</label>
          </div>
          <div className="titlelabel">
            <label className="formTitle">Forgot Password</label>
          </div>
          <form className="forgotForm">
            <div className="inputcol">
              <input
                type="email"
                className="forgot-input"
                placeholder="Enter email"
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </div>
            <span>{error}</span>

            <div className="btnCol">
              <button
                type="submit"
                className="submit-btn"
                onClick={handlePassword}
              >
                Send Email
              </button>
            </div>
          </form>
        </div>
      </div>
    </div >
  );
};

export default ForgotPassword;
