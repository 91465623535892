import React from "react";
import ReactPaginate from "react-paginate";

// let newChecked
function Pagination({
  count,
  perpage,
  setPerpage,
  setPageNumber,
  vendorRows,
  vendor,
}) {
  const handleRows = (e) => {
    setPerpage(e.target.value);
    setPageNumber(0);
  };
  const changePage = (e) => {
    const pageNumber = e.selected;
    setPageNumber(pageNumber);
    // setCheck(false)
    // newChecked=[]
  };
  return (
    <div className="d-flex justify-content-end">
      <div>
        <label className="selectLabel">
          {vendorRows && `[${vendorRows}/${vendor.count}]`} Rows Per Page:
        </label>
        <select
          onChange={(e) => handleRows(e)}
          className="rows-select"
          style={{ borderRadius: "5px" }}
          value={perpage}
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
      </div>
      {count > 1 ? (
        <div>
          <ReactPaginate
            breakLabel="...."
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={count}
            onPageChange={changePage}
            marginPagesDisplayed={2}
            // pageRangeDisplayed={2}
            containerClassName={"paginationBtns"}
            previousLinkClassName={"previousBtn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Pagination;
