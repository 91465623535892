import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, FastField } from "formik";
import * as Yup from "yup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../components/LoginPass.css";
import "../components/Login.css";
import { useHistory } from "react-router-dom";
import { InputAdornment, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../action/userAction";

const NewPassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [passCredential, setPassCredential] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [handleBlurError, setHandleBlurError] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [newPass, setNewPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);

  let otp = useSelector((state)=>state.otpReducer)
  let addforgot = useSelector((state)=>state.addforgot)


  const dispatch = useDispatch()

  useEffect(()=>{
  },[otp])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHandleBlurError({ ...handleBlurError, [name]: "" });
    setPassCredential({ ...passCredential, [name]: value });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value.length === 0) {
      setHandleBlurError({
        ...handleBlurError,
        [name]: "Field cannot be empty",
      });
    } else if (value.length < 8) {
      setHandleBlurError({
        ...handleBlurError,
        [name]: "Contain at least 8 characters",
      });
    } else {
      setHandleBlurError({
        ...handleBlurError,
        [name]: "",
      });
    }
  };

  const chechError = () => {
    if (passCredential.newPassword === passCredential.confirmPassword) {
      setError("");
      return true;
    } else {
      setError("Passwords does not match");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      handleBlurError.newPassword.length === 0 &&
      handleBlurError.confirmPassword.length === 0
    ) {
      if (chechError()) {
        const updatePasswordRequestBody = {
          "referenceCode" :addforgot.referenceCode,
          "newPassword" :passCredential.newPassword
        }
        dispatch(updatePassword(updatePasswordRequestBody,history));
      }
    }
  };

  return (
    <div className="background-img">
      <div className="forgot-outer">
        <div className="login-1">
          <div className="imagecol">
            <img
              src="https://nimapinfotech.com/wp-content/uploads/2019/01/nimap-logo.png"
              alt=""
              className="nimap-logo"
            />
          </div>
          <div className="invoicelabel">
            <label className="projectName">Invoice Management</label>
          </div>
          <div className="titlelabel">
            <label className="formTitle">Forgot Password</label>
          </div>

          <form onSubmit={handleSubmit}>
            <div style={{ margin: "20px 0px" }}>
              <TextField
                style={{
                  width: "400px",
                  fontSize: '17px',
                  fontWeight: "bold",
                }}
                className="forgotpass"
                required
                label="New Password"
                type={newPass ? "password" : "text"}
                variant="outlined"
                error={handleBlurError.newPassword.length === 0 ? false : true}
                size="large"
                name="newPassword"
                value={passCredential.newPassword}
                onChange={handleChange}
                onBlur={(e) => handleBlur(e)}
                InputLabelProps={{
                  style: {fontSize:"17px"}, 
               }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {newPass ? (
                        <VisibilityOffIcon
                          fontSize="large"
                          onClick={() => setNewPass(false)}
                        />
                      ) : (
                        <VisibilityIcon
                          fontSize="large"
                          onClick={() => setNewPass(true)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <div className="errorclass">
                {handleBlurError.newPassword.length !== 0
                  ? handleBlurError.newPassword
                  : ""}
              </div>
            </div>

            <div style={{ margin: "20px 0px" }}>
              <TextField
                style={{ width: "400px" }}
                required
                label="Confirm New Password"
                type={confirmPass ? "password" : "text"}
                variant="outlined"
                name="confirmPassword"
                error={
                  handleBlurError.confirmPassword.length === 0 ? false : true
                }
                value={passCredential.confirmPassword}
                onChange={(e) => handleChange(e)}
                onBlur={(e) => handleBlur(e)}
                InputLabelProps={{
                  style: {fontSize:"17px"}, 
               }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {confirmPass ? (
                        <VisibilityOffIcon
                          fontSize="large"
                          onClick={() => setConfirmPass(false)}
                        />
                      ) : (
                        <VisibilityIcon
                          fontSize="large"
                          onClick={() => setConfirmPass(true)}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <div className="errorclass">
                {handleBlurError.confirmPassword.length !== 0
                  ? handleBlurError.confirmPassword
                  : ""}
              </div>
              <div className="errorclass">{error}</div>
            </div>
            <button className="otpbtn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>  
  );
};

export default NewPassword;
