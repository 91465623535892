import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  POST_FORGOT_REQUEST,
  POST_FORGOT_SUCCESS,
  POST_FORGOT_FAILED,
  UPDATE_PROFILE_INFO_REQUEST,
  UPDATE_PROFILE_INFO_SUCCESS,
  UPDATE_PROFILE_INFO_FAILED,
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILD,
} from "../constant/adminConstant/userConstant";

const initialstate = {
  currentUser: {},
  isLoggedIn: false,
  isAuth: localStorage.getItem("token") ? true : false,
};
export const signinReducer = (state = initialstate, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
        isAuth: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        loading: false,
        isAuth: true,
        isLoggedIn: true,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

const forgotState = {
  isSubmit: false,
  isError: false,
  errors: "",
  message :"",
  referenceCode:""
};

const otpState = {
  isSubmit: false,
  isError: false,
  refCode:""
}


export const forgotReducer = (state = forgotState, action) => {
  switch (action.type) {
    case POST_FORGOT_REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: true,
      };
    case POST_FORGOT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        forgotpass: action.payload,
        isSubmit: true,
        message:action.payload.message,
        referenceCode:action.payload.referenceCode
      };
    case POST_FORGOT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isError: true,
        errors: { ...action.payload },
      };
    default:
      return state;
  }
};

export const otpReducer = (state = otpState, action) => {
  switch (action.type) {
    case VALIDATE_OTP_REQUEST:
      return {
        ...state,
        isSubmit: false,
      };

    case VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        isSubmit: true,
        refCode:action.payload.refCode
      };
    case VALIDATE_OTP_FAILD:
      return {
        ...state,
        isError: true,
        errors: { ...action.payload }
      };

    default:
      return state;
  }
};

export const updatePersonalInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_INFO_REQUEST:
      return {
        // ...state,
        // loading: true,
      };
    case UPDATE_PROFILE_INFO_SUCCESS:
      return {
        // ...state,
        // loading: false,
        // success: true,
        // uProfile: action.payload,
      };
    case UPDATE_PROFILE_INFO_FAILED:
      return {
        // ...state,
        // loading: false,
        // error: action.payload,
      };
    default:
      return state;
  }
};
