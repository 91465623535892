import React  from 'react'
import Login from './components/Login';
import AdminRoutes from "./Routes/adminRoutes"
import VendorRoutes from "./Routes/vendorRoutes"
import ApproverRoutes from "./Routes/approverRoutes"
import FinancerRoutes from "./Routes/financerRoutes"
import VendorUserRoutes from './Routes/vendorUserRoutes';
import { Route, Switch, withRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/font-awesome/css/font-awesome.css'
import './App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './components/forgotPassword';
import SendOtp from './components/sendOtp';
import PasswordChange from './components/PasswordChange';
import VendorRegisterForm from './components/vendors/vendorRegisterForm';
import SubmitOtp from "./components/SubmitOtp";
import NewPassword from "./components/NewPassword"; 
import PageNotFound from './components/PageNotFound';

function App() {
  let role = localStorage.getItem('role') 

  return (<>
      <ToastContainer className="toastsContainer" autoClose={1000}/>
      <div className="App">
          <Switch>
            <Route path="/forgotPassword" component={ForgotPassword} />
            <Route path="/sendOtp" component={SendOtp} />
            <Route path="/passwordChange" component={PasswordChange}/>
            <Route path="/submitotp" component={SubmitOtp} />
          <Route path="/newpassword" component={NewPassword} />
            <Route path="/register" component={VendorRegisterForm}/>
            {/* <Route path="*" component={Login} /> */}

            {role === 'admin' && <AdminRoutes/>}
            {role === 'superadmin' && <AdminRoutes/>}
            {role === 'vendor' && <VendorRoutes/>}
            {role === 'approver' && <ApproverRoutes/>}
            {role === 'financer' && <FinancerRoutes/>}
            {role === 'vendoruser' && <VendorUserRoutes/>}

            <Route exact path="/" component={Login}/>
            <Route path="*" component={PageNotFound} />
          </Switch>
      </div>
      </>
  )
}

export default withRouter(App)
