import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCookie } from "../utility";
import { toast } from "react-toastify";
import Nimaplogo from "../components/images/Nimapmng.png";

import "./Login.css";
import { signinAction, signinGoogleAction } from "../action/userAction";

import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import showPwdImg from "./images/show-password.svg";
import hidePwdImg from "./images/hide-passwordd.svg";

import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  labelText: {
    color: "white",
    float: "left",
  },
  text: {
    background: "white",
  },
  textColor: {
    color: "black",
    fontSize: "1.4rem",
    fontWeight: 100,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0dcaf0",
  },
}));

const LOGIN_USER = "loginUser";

export default function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const signIn = (e) => {
    e.preventDefault();
    const user = { email, password };
    dispatch(signinAction(user, history, rememberMe));
  };

  const handelRememberMe = (e) => {
    const { checked } = e.target;
    setRememberMe(checked);
  };

  useEffect(() => {
    const loginUser = getCookie(LOGIN_USER);
    if (loginUser !== null) {
      const [email, password] = loginUser.split("$");
      setEmail(email);
      setPassword(password);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="background-img ">
        <div className="container-fluid">
          <div className="login-row">
            <div className="bucket">
              <div className="loginImg">
                <img
                  src={`${Nimaplogo}`}
                  className="pb-1 logo"
                  alt="NimapLogo"
                />
              </div>
              <form className="formclass" onSubmit={signIn}>
                <div className="fieldspace">
                  <div className="email">
                    <input
                      aria-invalid="false"
                      id="email"
                      name="email"
                      placeholder="Email Id"
                      required=""
                      type="text"
                      className="login-input"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    {/* <div className="fieldspace">
                          <span style={{ color: "red" }}></span>
                        </div> */}
                  </div>
                </div>
                <div className="pwds-container">
                  <input
                    aria-invalid="false"
                    id="password"
                    name="password"
                    placeholder="Password"
                    required=""
                    type={isRevealPwd ? "text" : "password"}
                    className="login-input"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <img
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    alt="password icon"
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  />
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  backgroundColor="#0dcaf0"
                  className={classes.submit}
                >
                  Log In
                </Button>
                <div className="RememberMe">
                  <div className="remember">
                    <input
                      className="remeberBox"
                      type="checkbox"
                      id="remember_me"
                      name="remember_me"
                      value={rememberMe}
                      onChange={handelRememberMe}
                    />
                    <label className="rememberLabel" htmlFor="remember_me">
                      Remember Me
                    </label>
                  </div>
                  <div className="forgot">
                    <Link className="link-text" to="/forgotPassword">
                      Forgot password?
                    </Link>
                  </div>
                </div>
                <div>
                  {/* <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      backgroundColor="#0dcaf0"
                      className={classes.submit}
                      onClick={() => login()}
                    >
                      Login with google
                    </Button> */}

                  <GoogleLogin
                    auto_select={false}
                    shape={"pill"}
                    scope="email"
                    width="390px"
                    // size='large'
                    onSuccess={(credentialResponse) => {
                      let decoded = jwt_decode(credentialResponse.credential);
                      let data = {
                        email: decoded.email,
                      };
                      dispatch(signinGoogleAction(data, history));
                    }}
                    // onSuccess={handleLogin}
                    onError={() => {
                      toast.error("Google login failed");
                    }}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
