import * as finType from "../../constant/financer/financerConstants";

export const getFinancerorgReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_FINANCERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_FINANCERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financer: action.payload,
      };
    case finType.GET_FINANCERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getOverdueReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_OVERDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_OVERDUE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        overdue: action.payload,
      };
    case finType.GET_OVERDUE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerStatus: action.payload,
      };
    case finType.UPDATE_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getPaidInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_PAIDINV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_PAIDINV_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        paidInv: action.payload,
      };
    case finType.GET_PAIDINV_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Rejection
export const addRejectfinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.ADD_REJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.ADD_REJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejectmsg: action.payload,
      };
    case finType.ADD_REJECT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Archive list
export const archivelistReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.ARCHIVELIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.ARCHIVELIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        archivedlist: action.payload,
      };
    case finType.ARCHIVELIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Archive
export const archiveReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.ARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        archived: action.payload,
      };
    case finType.ARCHIVE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//Unarchive
export const unarchiveReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.UNARCHIVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.UNARCHIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        unarchived: action.payload,
      };
    case finType.UNARCHIVE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const selectedInvoices = (state = [], { type, payload }) => {
  switch (type) {
    case finType.BULK_PAYMENT_INVOICE_LIST:
      // return [...state,...payload]
      let arr1 = [...state, ...payload];
      let finalList1 = arr1.filter((item, index) => {
        return index === arr1.findIndex((obj) => item.id === obj.id);
        // return arr1.indexOf(item) === index;
      });
      return finalList1;

    case finType.REMOVE_FROM_BULK_PAYMENT_INVOICE_LIST:
      // for(let i = 0 ; i < payload.length ; i++){
      //     for(let j = 0 ; j < state.length ; j++){
      //         if(state[j]?.id===payload[i]?.id){
      //             state.splice(j,1)
      //             j=0;
      //         }
      //     }
      // }

      state = state.filter((stateItem) => {
        return !payload.some((payloadItem) => {
          return stateItem?.id === payloadItem?.id;
        });
      });

      return [...state];

    case finType.EMPTY_SELECETED_INVOICE_LIST:
      return [];

    default:
      return state;
  }
};

export const formikData = (state = {}, { type, payload }) => {
  switch (type) {
    case finType.UPDATE_FORMIK_DATA:
      return payload;

    case finType.CLEAR_FORMIK_DATA:
      return {};

    case finType.REMOVE_FROM_FORMIK_DATA:
      ["paymentType", "amount", "reason"].forEach((key) => {
        delete state[key + payload];
      });
      return state;
    default:
      return state;
  }
};
