import { ADD_PERMISSION_REQUEST, ADD_PERMISSION_SUCCESS, ADD_PERMISSION_FAILED } from "../../constant/adminConstant/manageuser"

export const addPermissionReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_PERMISSION_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_PERMISSION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                permission: action.payload
            }
        case ADD_PERMISSION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}