import {
    ADD_ROLE_REQUEST, ADD_ROLE_SUCCESS, ADD_ROLE_FAILED,
    GET_ROLE_REQUEST, GET_ROLE_SUCCESS, GET_ROLE_FAILED,
    EDIT_ROLE_REQUEST, EDIT_ROLE_SUCCESS, EDIT_ROLE_FAILED,
    GET_ROLELIST_REQUEST, GET_ROLELIST_SUCCESS, GET_ROLELIST_FAILED
} from "../constant/adminConstant/roleConstant"

export const addRoleListReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                addRole: action.payload
            }
        case ADD_ROLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getRoleListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ROLELIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ROLELIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                roleUser: action.payload
            }
        case GET_ROLELIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const getRolesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                roles: action.payload
            }
        case GET_ROLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const editRoleReducer = (state = {}, action) => {
    switch (action.type) {
        case EDIT_ROLE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case EDIT_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                roleUpdate: action.payload
            }
        case EDIT_ROLE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}