export const handleValidation = (value, setInputs) => {
  let errors = {};
  let formIsValid = true;
  if (!value.username) {
    formIsValid = false;
    errors["username"] = "Username is required";
  } else if (!/^[a-zA-Z]+ [a-zA-Z]+$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "Enter Full Name";
  } else if (!/^.{4,50}$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "you are exceeding maximum limit";
  }

  if (!value.emailId) {
    formIsValid = false;
    errors["emailId"] = "Email Id is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.emailId)) {
    formIsValid = false;
    errors["emailId"] = "Invalid Email";
  }

  if (!value.mobile) {
    formIsValid = false;
    errors["mobile"] = "Mobile number is required";
  } else if (!/(7|8|9)\d{9}$/.test(value.mobile)) {
    formIsValid = false;
    errors["mobile"] = "Invalid mobile Number";
  }
  setInputs((prevalue) => {
    return {
      ...prevalue, // Spread Operator
      errors: errors,
    };
  });
  return formIsValid;
};

export const handleAdminValidation = (value, setInputs) => {
  let errors = {};
  let formIsValid = true;
  if (!value.username) {
    formIsValid = false;
    errors["username"] = "Username is required";
  } else if (!/^[a-zA-Z]+ [a-zA-Z]+$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "Enter Full Name";
  } else if (!/^.{4,50}$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "you are exceeding maximum limit";
  }

  if (!value.emailId) {
    formIsValid = false;
    errors["emailId"] = "Email Id is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.emailId)) {
    formIsValid = false;
    errors["emailId"] = "Invalid Email";
  }

  if (!value.mobile) {
    formIsValid = false;
    errors["mobile"] = "Mobile number is required";
  } else if (!/(7|8|9)\d{9}$/.test(value.mobile)) {
    formIsValid = false;
    errors["mobile"] = "Invalid mobile Number";
  }

  if (!value.organization) {
    formIsValid = false;
    errors["organization"] = "Organization is required";
  }

  if (!value.creditDate) {
    formIsValid = false;
    errors["creditDate"] = "Credit period is required";
  }

  // if(!value.gstper){
  //     formIsValid = false;
  //     errors['gstper']="GST is required";
  // }

  // if(!value.tdsper){
  //     formIsValid = false;
  //     errors['tdsper']="TDS is required";
  // }
  setInputs((prevalue) => {
    return {
      ...prevalue, // Spread Operator
      errors: errors,
    };
  });
  return formIsValid;
};

export const handleVendorValidation = (value, setInputs) => {
  let errors = {};
  let formIsValid = true;
  if (!value.username) {
    formIsValid = false;
    errors["username"] = "Username is required";
  } else if (!/^[a-zA-Z]+ [a-zA-Z]+$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "Enter Full Name";
  } else if (!/^.{4,50}$/.test(value.username)) {
    formIsValid = false;
    errors["username"] = "you are exceeding maximum limit";
  }

  if (!value.emailId) {
    formIsValid = false;
    errors["emailId"] = "Email Id is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.emailId)) {
    formIsValid = false;
    errors["emailId"] = "Invalid Email";
  }

  if (!value.mobile) {
    formIsValid = false;
    errors["mobile"] = "Mobile number is required";
  } else if (!/(7|8|9)\d{9}$/.test(value.mobile)) {
    formIsValid = false;
    errors["mobile"] = "Invalid mobile Number";
  }

  if (!value.organization) {
    formIsValid = false;
    errors["organization"] = "Organization is required";
  }
  setInputs((prevalue) => {
    return {
      ...prevalue, // Spread Operator
      errors: errors,
    };
  });
  return formIsValid;
};

export const passwordValidation = (value, setInputs) => {
  let error = {};
  let formIsValid = true;
  if (!value.current) {
    formIsValid = false;
    error["current"] = "Old password is required";
  }
  if (!value.newpassword) {
    formIsValid = false;
    error["newpassword"] = "New password is required";
  }
  if (value.newpassword != value.confirmpass) {
    formIsValid = false;
    error["confirmpass"] = "Password did not match";
  }

  setInputs((prev) => {
    return {
      ...prev,
      error: error,
    };
  });
  return formIsValid;
};
