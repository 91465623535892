import {
    GET_VENDORLIST_REQUEST, GET_VENDORLIST_SUCCESS, GET_VENDORLIST_FAILED,
    GET_INVOICELIST_REQUEST, GET_INVOICELIST_SUCCESS, GET_INVOICELIST_FAILED,
    GET_FILTERAPPROVER_REQUEST, GET_FILTERAPPROVER_SUCCESS, GET_FILTERAPPROVER_FAILED,
    ADD_ACCEPTINV_REQUEST, ADD_ACCEPTINV_SUCCESS, ADD_ACCEPTINV_FAILED,
    ADD_REJECTINV_REQUEST, ADD_REJECTINV_SUCCESS, ADD_REJECTINV_FAILED,
    GET_APPROVERSEARCH_REQUEST, GET_APPROVERSEARCH_FAILED, GET_APPROVERSEARCH_SUCCESS,
    GET_INVOICEAPPROVERSEARCH_REQUEST, GET_INVOICEAPPROVERSEARCH_SUCCESS, GET_INVOICEAPPROVERSEARCH_FAILED,
    GET_APPROVERSTATUS_REQUEST, GET_APPROVERSTATUS_SUCCESS, GET_APPROVERSTATUS_FAILED,
    GET_APPROVERAMOUNT_REQUEST, GET_APPROVERAMOUNT_SUCCESS, GET_APPROVERAMOUNT_FAILED,
    INVOICELISTLEN_REQUEST, INVOICELISTLEN_SUCCESS, INVOICELISTLEN_FAILED, GET_APPROVER_VENREQUEST_REQUEST,GET_APPROVER_VENREQUEST_SUCCESS,GET_APPROVER_VENREQUEST_FAILED

} from "../../constant/approverConstant/approverconstant";

export const getVendorlistReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_VENDORLIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_VENDORLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                vendorlist: action.payload
            }
        case GET_VENDORLIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getApproverInvoiceLenReducer = (state = {}, action) => {
    switch (action.type) {
        case INVOICELISTLEN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case INVOICELISTLEN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                invoiceLen: action.payload
            }
        case INVOICELISTLEN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getApproverInvoicelistReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_INVOICELIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_INVOICELIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                invoicelist: action.payload
            }
        case GET_INVOICELIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getApproverFilterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_FILTERAPPROVER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_FILTERAPPROVER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                filterApprover: action.payload
            }
        case GET_FILTERAPPROVER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const addAcceptinvReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_ACCEPTINV_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_ACCEPTINV_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                acceptinv: action.payload
            }
        case ADD_ACCEPTINV_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const addRejectinvReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_REJECTINV_REQUEST:
            return {
                ...state,
                loading: true
            }
        case ADD_REJECTINV_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                rejectinv: action.payload
            }
        case ADD_REJECTINV_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const getApproverSearchAction = (state = {}, action) => {
    switch (action.type) {
        case GET_APPROVERSEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_APPROVERSEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                searchfield: action.payload
            }
        case GET_APPROVERSEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getInvoiceApproverSearchAction = (state = {}, action) => {
    switch (action.type) {
        case GET_INVOICEAPPROVERSEARCH_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_INVOICEAPPROVERSEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                searchfield: action.payload
            }
        case GET_INVOICEAPPROVERSEARCH_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getApproverStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_APPROVERSTATUS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_APPROVERSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                approverStatus: action.payload
            }
        case GET_APPROVERSTATUS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getapproverAmountStatusReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_APPROVERAMOUNT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GET_APPROVERAMOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                amtStatus: action.payload
            }
        case GET_APPROVERAMOUNT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getApproverVendorRequestReducer = (state={}, action) => {
    switch(action.type){
        case GET_APPROVER_VENREQUEST_REQUEST:
            return { 
                ...state,
                 loading: true
            }
        case GET_APPROVER_VENREQUEST_SUCCESS:
            return { 
                ...state, 
                loading: false, 
                success: true, 
                getRequest: action.payload 
            }
        case GET_APPROVER_VENREQUEST_FAILED:
            return { 
                ...state, 
                loading: false, 
                error: action.payload 
            }
        default:
            return state
    }
}