import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./addUser.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// import { useRef } from "react";
import { postservice, putservice } from "../../api/apiservices/service";
import { ADD_BANK_DATA, UPDATE_BANK_DATA } from "../../resources/APIEndpoints";
import { toast } from "react-toastify";

const bankNoRegEx = /^[A-Za-z0-9]{9,18}$/;

export default function AddBankData({
  open,
  close,
  bankCsvData,
  loadData,
  getBankData,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const csvRef = useRef(null)
  const submitUser = async (values) => {
    setIsSubmitting(true);
    loadData
      ? putservice(`${UPDATE_BANK_DATA}/${loadData.id}`, {
          bankName: values.bankdropdown,
          accountNumber: values.accountNumber,
        })
          .then((res) => {
            toast.success(res?.data?.message || "Bank Data added successfully");
            setIsSubmitting(false);
            close();
            getBankData();
          })
          .catch((e) => {
            setIsSubmitting(false);
          })
      : postservice(ADD_BANK_DATA, {
          bankName: values.bankdropdown,
          accountNumber: values.accountNumber,
        })
          .then((res) => {
            toast.success(res?.data?.message || "Bank Data added successfully");
            setIsSubmitting(false);
            close();
            getBankData();
          })
          .catch((e) => {
            setIsSubmitting(false);
          });
  };

  const initialValue = {
    bankdropdown: loadData ? loadData.bank_name : "",
    accountNumber: loadData ? loadData.account_number : "",
    csvFile: "",
  };

  const userSchema = Yup.object().shape({
    bankdropdown: Yup.string()
      .required("Bank Name is required")
      .min(2, "Minimum 2 characters required")
      .max(60, "Maximum 60 characters allowed"),
    accountNumber: Yup.string()
      .required("Account Number is required")
      .min(8, "Minimum 8 characters required")
      .max(19, "Maximum 19 characters allowed")
      .matches(bankNoRegEx, "Invalid Account number")
      .required("Account number is required"),
    // csvFile: Yup.mixed()
    //   .required("CSV file is required")
    //   .test("fileFormat", "Unsupported file format", (value) => {
    //     return (
    //       value &&
    //       [
    //         "text/csv",
    //       ].includes(value?.type)
    //     );
    //   })
  });

  const handleChange = (e, setFieldValue) => {
    setFieldValue("bankdropdown", e.target.value);
  };
  return (
    <>
      <div className="modals-content">
        <Modal open={open} onClose={close}>
          <Box className="modalclass adminVenBox">
            <Typography className="modal-title">
              Bank Data
              <div className="border-0 pt-1">
                <hr className="mt-1 bottom_borderColor_overview"></hr>
              </div>
            </Typography>
            <Formik
              initialValues={initialValue}
              validationSchema={userSchema}
              onSubmit={(values) => submitUser(values)}
            >
              {({ errors, touched, setFieldValue, values, setTouched }) => {
                // if(errors.csvFile) {
                //   csvRef.current.value = ""
                // }
                return (
                  <Form>
                    <Row className="form_row">
                      <div lg={12} className=" col-12">
                        <div className="flexrowaddbank">
                          <div className=" col-4">
                            <label
                              className="lblbank"
                              htmlFor="bankmodaldropdown"
                            >
                              Bank Name<span className="mandatory">*</span>{" "}
                            </label>
                          </div>
                          <div className="addbank-input-div col-8">
                            <Field
                              className="bankmodaldropdown inp-bankname"
                              name="bankdropdown"
                              onChange={(e) => {
                                handleChange(e, setFieldValue);
                              }}
                              value={values.bankdropdown}
                            />
                            {/* <Field
                            as="select"
                            className="bankmodaldropdown"
                            name="bankdropdown"
                            onChange={(e) => {
                              handleChange(e, setFieldValue)
                            }}
                            value={values.bankdropdown}
                           
                          >
                            <option value="">Select Bank Name</option>
                            {bankCsvData.map(bank => <option key={bank.bank_name} value={bank.bank_name}>{bank.bank_name.toUpperCase()}</option>)}
                          </Field> */}
                            <span className="add-vendor-error-text">
                              {errors.bankdropdown && touched.bankdropdown ? (
                                <div>{errors.bankdropdown}</div>
                              ) : null}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    <Row className="form_row">
                      <div lg={12} className=" col-12">
                        <div className="flexrowaddbank">
                          <div className=" col-4">
                            <label
                              className="lblbank"
                              htmlFor="bankmodaldropdown"
                            >
                              Account Number<span className="mandatory">*</span>{" "}
                            </label>
                          </div>
                          <div className="addbank-input-div col-8">
                            <Field
                              type="number"
                              className="bankmodaldropdown inp-bankname"
                              name="accountNumber"
                              onChange={(e) => {
                                setFieldValue("accountNumber", e.target.value);
                              }}
                              value={values.accountNumber}
                            />
                            <span className="add-vendor-error-text">
                              {errors.accountNumber && touched.accountNumber ? (
                                <div>{errors.accountNumber}</div>
                              ) : null}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Row>
                    {/* <Row className="form_row">
                    <div lg={12} className=" col-12">
                      <div className="flexrowaddbank">
                        <div className=" col-4">
                          <label className="lblbank">
                            Upload<span className="mandatory">*</span>{" "}
                          </label>
                        </div>

                        <div className="addbank-input-div col-8">
                          <input type="file" className="csvfile-input" ref={csvRef} 
                            onBlur={() => {
                              setTouched({
                                ...touched,
                                csvFile: true
                              })
                            }}
                            onChange={(e) => {
                              if(e.target.files[0]){
                                setFieldValue("csvFile", e.target.files[0])
                              }
                            }}
                          />
                          <span className="add-vendor-error-text">
                            {errors.csvFile && touched.csvFile ? (
                              <div>{errors.csvFile}</div>
                            ) : null}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row> */}

                    <Row className="form_row">
                      <Col lg={6} className="text-right">
                        <button
                          className="add-btns"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {loadData ? "Update" : "Add"}
                        </button>
                      </Col>
                      <Col lg={6} className="cnclCols">
                        <button className="cancel-btns" onClick={close}>
                          Cancel
                        </button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Modal>
      </div>
    </>
  );
}
