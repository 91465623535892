import React from 'react';
import "./PageNotFound.css";
const PageNotFound = () => {
  return (
    <>
    <div className="main-div">
     <p className='pleasewaittext'>Page Not Found</p>
    </div>
    </>
  )
}
export default PageNotFound