import { getservice } from "../api/apiservices/service";
import * as countType from "../constant/adminConstant/adminCount";
import * as roleType from "../constant/adminConstant/roleConstant";
import * as appType from "../constant/approverConstant/approverconstant";
import * as venType from "../constant/vendorConstant/vendorConstants";
import { APPROVER_AMT, APPROVER_COUNT, APPROVER_TOTAL, FINADMIN_AMT, FINADMIN_COUNT, FINANCER_TOTAL, VENDOR_AMT, VENDOR_COUNT, VENDOR_TOTAL } from "../resources/APIEndpoints"

//Vendor status 
export const getFirstStatusAction = () => async (dispatch) => {
    dispatch({ type: venType.GET_VENDORSTATUS_REQUEST })
    try {
        const response = await getservice(VENDOR_COUNT)
        dispatch({ type: venType.GET_VENDORSTATUS_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: venType.GET_VENDORSTATUS_FAILED, payload: error })
    }
}
//Vendor amount status
export const getAmountVendorStatusAction = () => async (dispatch) => {
    dispatch({ type: venType.GET_VENDORAMOUNT_REQUEST })
    try {
        const response = await getservice(VENDOR_AMT)
        dispatch({ type: venType.GET_VENDORAMOUNT_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: venType.GET_VENDORAMOUNT_FAILED, payload: error })
    }
}

//Approver Status
export const getApproverStatusAction = () => async (dispatch) => {
    dispatch({ type: appType.GET_APPROVERSTATUS_REQUEST })
    try {
        const response = await getservice(APPROVER_COUNT)
        dispatch({ type: appType.GET_APPROVERSTATUS_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: appType.GET_APPROVERSTATUS_FAILED, payload: error })
    }
}

//Approver Amount Status
export const getApproverAmountStatusAction = () => async (dispatch) => {
    dispatch({ type: appType.GET_APPROVERAMOUNT_REQUEST })
    try {
        const response = await getservice(APPROVER_AMT)
        dispatch({ type: appType.GET_APPROVERAMOUNT_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: appType.GET_APPROVERAMOUNT_FAILED, payload: error })
    }
}


//financer and admin status
export const getFinancerAdminStatusAction = () => async (dispatch) => {
    dispatch({ type: roleType.GET_AFSTATUS_REQUEST })
    try {
        const response = await getservice(FINADMIN_COUNT)
        dispatch({ type: roleType.GET_AFSTATUS_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: roleType.GET_AFSTATUS_FAILED, payload: error })
    }
}

export const getFinancerAdminAmountAction = () => async (dispatch) => {
    dispatch({ type: roleType.GET_AFAMOUNT_REQUEST })
    try {
        const response = await getservice(FINADMIN_AMT)
        dispatch({ type: roleType.GET_AFAMOUNT_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: roleType.GET_AFAMOUNT_FAILED, payload: error })
    }
}

export const getAllvendorCountAction = () => async (dispatch) => {
    dispatch({ type: countType.ADMINVENDOR_REQUEST })
    try {
        const response = await getservice(VENDOR_TOTAL)
        dispatch({ type: countType.ADMINVENDOR_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: countType.ADMINVENDOR_FAILED, payload: error })
    }
}

export const getAllapproverCountAction = () => async (dispatch) => {
    dispatch({ type: countType.ADMINAPPROVER_REQUEST })
    try {
        const response = await getservice(APPROVER_TOTAL)
        dispatch({ type: countType.ADMINAPPROVER_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: countType.ADMINAPPROVER_FAILED, payload: error })
    }
}

export const getAllfinancerCountAction = () => async (dispatch) => {
    dispatch({ type: countType.ADMINFINANCER_REQUEST })
    try {
        const response = await getservice(FINANCER_TOTAL)
        dispatch({ type: countType.ADMINFINANCER_SUCCESS, payload: response.data })
    }
    catch (error) {
        dispatch({ type: countType.ADMINFINANCER_FAILED, payload: error })
    }
}

