import * as invMng from "../../constant/vendorConstant/invoicemng";

export const createInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addInv: action.payload,
      };
    case invMng.CREATE_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editInvoiceReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.EDIT_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.EDIT_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        updateinv: action.payload,
      };
    case invMng.EDIT_INVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getDataReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.GET_INVOICEDATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GET_INVOICEDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoiceuser: action.payload,
      };
    case invMng.GET_INVOICEDATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getInvoivceMngtReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.GET_INVOICEMNG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GET_INVOICEMNG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoicedata: action.payload,
      };
    case invMng.GET_INVOICEMNG_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteInvoivceMngtReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.DELETE_INVOICEMNG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.DELETE_INVOICEMNG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoicedel: action.payload,
      };
    case invMng.DELETE_INVOICEMNG_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getFilterInvReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.GET_INVFILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GET_INVFILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invfilter: action.payload,
      };
    case invMng.GET_INVFILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const addFileReducer = (state = { pathdocs: [] }, action) => {
  switch (action.type) {
    case invMng.ADD_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "INVOICE_INV":
      return {
        ...state,
        loading: false,
        pathinvoice: action.payload,
      };
    case "DOCS":
      return {
        ...state,
        loading: false,
        pathdocs: [...state.pathdocs, action.payload],
      };
    case invMng.ADD_FILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateFileReducer = (state = { pathdocuments: null }, action) => {
  switch (action.type) {
    case invMng.UPDATE_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_INVOICE":
      return {
        ...state,
        loading: false,
        pathinv: action.payload,
      };
    case "UPDATE_DOCS":
      return {
        ...state,
        loading: false,
        pathdocuments: action.payload,
      };
    case invMng.UPDATE_FILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//search
export const getSearchReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.GET_INVOICEMNGSEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GET_INVOICEMNGSEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        searchfield: action.payload,
      };
    case invMng.GET_INVOICEMNGSEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getViewpdfReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case invMng.GET_VIEWINVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GET_VIEWINVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        viewpdf: action.payload,
      };
    case invMng.GET_VIEWINVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getGstTdsReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.GST_TDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.GST_TDS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        gst: action.payload.gst,
        tds: action.payload.tds,
      };
    case invMng.GST_TDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getupdateInvDetailsReducer = (
  state = { success: null },
  action
) => {
  switch (action.type) {
    case invMng.INVDETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case invMng.INVDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        getinv: action.payload,
      };
    case invMng.INVDETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const profileFileReducer = (state = {}, action) => {
  switch (action.type) {
    case invMng.PROFILE_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "GST_FILE":
      return {
        ...state,
        loading: false,
        success: true,
        gst: action.payload,
      };
    case "PAN_FILE":
      return {
        ...state,
        loading: false,
        success: true,
        pan: action.payload,
      };
    case invMng.PROFILE_FILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
