import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../resources/APIEndpoints";

export const privateRequest = axios.create({
  timeout: 20000,
  baseURL: BASE_URL,
});

const getToken = () => {
  if (localStorage.getItem("token") !== null) {
    const tokenX = localStorage.getItem("token");
    return tokenX;
  }
};

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${getToken()}`;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    window.location = "/";
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
  }
  toast.error(error.response.data.message);
  return Promise.reject(error);
};

privateRequest.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

privateRequest.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
