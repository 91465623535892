import * as finType from "../constant/adminConstant/financerConstant";
import * as financer from "../constant/financer/financerConstants";

export const addFinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.ADD_FINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.ADD_FINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerAdd: action.payload,
      };
    case finType.ADD_FINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUpcomingOverdueFinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_FINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_FINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        FinanceOverdue: action.payload,
      };
    case finType.GET_FINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getOverviewReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_FINANCERS_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_FINANCERS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        Overview: action.payload,
      };
    case finType.GET_FINANCERS_OVERVIEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getFinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_FINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_FINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerGet: action.payload,
      };
    case finType.GET_FINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editFinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.EDIT_FINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.EDIT_FINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerEdit: action.payload,
      };
    case finType.EDIT_FINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteFinancerReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.DELETE_FINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.DELETE_FINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerDel: action.payload,
      };
    case finType.DELETE_FINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const accendingReducer = (state = {}, action) => {
  switch (action.type) {
    case financer.GET_ACCENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case financer.GET_ACCENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deleteInfo: action.payload,
      };
    case financer.GET_ACCENDING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get paid invoice list in financer modal
export const paidInvFinanceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_PAIDFINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_PAIDFINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        paidinvfin: action.payload,
      };
    case finType.GET_PAIDFINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get partial invoice list in financer modal
export const partialInvFinanceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_PARTIALFINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_PARTIALFINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        partialinvfin: action.payload,
      };
    case finType.GET_PARTIALFINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get rejected invoice list in financer modal
export const rejInvFinanceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_REJFINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_REJFINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejinvfin: action.payload,
      };
    case finType.GET_REJFINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get overdue invoice list in financer modal
export const overdueFinanceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_OVERDUEADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_OVERDUEADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        overdueinv: action.payload,
      };
    case finType.GET_OVERDUEADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get all invoice list in financer modal
export const allInvFinanceReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.GET_ALLFINANCER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.GET_ALLFINANCER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        allinvfin: action.payload,
      };
    case finType.GET_ALLFINANCER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//PADI STATUS
export const paidStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.PAID_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.PAID_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        paidStatus: action.payload,
      };
    case finType.PAID_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//REJECT STATUS
export const rejectStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.REJECT_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.REJECT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejectStatus: action.payload,
      };
    case finType.REJECT_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

// financer pay pending

export const getfinancerPaymentPendingReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.FINANCER_PAYMENT_PENDING__REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.FINANCER_PAYMENT_PENDING__SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerPaymentPending: action.payload,
      };
    case finType.FINANCER_PAYMENT_PENDING__FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getfinancerPaidReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.FINANCER_PAID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.FINANCER_PAID__SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerPaid: action.payload,
      };
    case finType.FINANCER_PAID__FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getfinancerPartialPaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.FINANCER_PAYMENT_PARTIAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.FINANCER_PAYMENT_PARTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerPartialPaid: action.payload,
      };
    case finType.FINANCER_PAYMENT_PARTIAL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getfinancerRejectedReducer = (state = {}, action) => {
  switch (action.type) {
    case finType.REJECT_PAYMENT_PAID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case finType.REJECT_PAYMENT_PAID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        financerRejected: action.payload,
      };
    case finType.REJECT_PAYMENT_PAID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
