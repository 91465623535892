import React from 'react';
import { useState, useEffect } from 'react';
import '../loader/Loader.css';

const delay = 10;

const Loader = () => {
  const [show, setShow] = useState(false);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShow(true), delay * 1000);
      return () => {
        clearTimeout(timer1);
      };
    },[]);

  return show ? (
    <div className="DataNotFound text-center" style={{ marginTop: '15%' }}>
      <h2> No Data Found </h2>
    </div>
  ) : (
    <div>
      <div className="MyLoader">
        <div className="MyMyLoaderOuter" />
        <div className="MyMyLoaderMiddle" />
        <div className="MyMyLoaderInner" />
      </div>
    </div>
  );
};

export default Loader;
