import React, { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory, Link } from "react-router-dom";
import "./navbar.css";
import ChangePassword from "../modal/changePassword";
import InvoiceLogo from "../images/Nimapmng.png";
import { makeStyles } from "@material-ui/core/styles";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { addCommasToNumber, removeCommasConvertToNumber } from "../../utility";
import { useDispatch } from 'react-redux';
import { clearFormikData, emptySelectedInvoice } from "../../action/financerAction";

const drawerWidth = "239px";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 0,
    bgcolor: "#f7fcff",
  },

  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth})`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: 104,
    padding: theme.spacing(2),
  },

  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Navbar(props) {
  const { setSearch, name, toggle } = props;
  const classes = useStyles();

  const [passopen, setPassopen] = useState(false);
  const passwordOpen = () => {
    setPassopen(true);
    handleClose();
  };
  const passwordClose = () => setPassopen(false);

  const [personalOpen, setPersonalOpen] = useState(false);
  const personalInfoOpen = () => {
    setPersonalOpen(true);
    handleClose();
  };
  const personalInfoClose = () => setPersonalOpen(false);

  let user = localStorage.getItem("name");
  const username = user && user.charAt(0).toUpperCase() + user.slice(1);

  
  const history = useHistory();
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  
  function logout() {
    localStorage.clear();
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    dispatch(emptySelectedInvoice())
    dispatch(clearFormikData())
    history.push("/");
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let timeout = null;

  const handleChange = (e) => {
    //removeCommasConvertToNumber(e.target.value);
    let arr2 = e.target.value.split(",");
    let string1 = arr2.join("");
    let isString = isNaN(string1);

    clearTimeout(timeout);
    timeout = setTimeout(function () {
      isString ? setSearch(e.target.value) : setSearch(string1);
    }, 500);
  };

  return (
    <>
      <nav className="navbar border">
        <div className="nav-main">
          <div className="bg-light2">
            <div className="cursor-pointer drawericon">
              <DehazeIcon style={{ fontSize: "x-large" }} onClick={toggle} />
            </div>
            <div className="cursor-pointer">
              <img
                src={`${InvoiceLogo}`}
                className="nimapLogo"
                alt="Company_Logo"
                draggable="false"
              />
            </div>
          </div>
          <div className="text-div">
            <div className="panels-title">{name}</div>
          </div>
          <div className="searchCol">
            {name === "Dashboard" ? (
              ""
            ) : name === "Create Invoice" ? (
              ""
            )
            : name === "Upload Invoices" ? (
              ""
            )  
            : name === "Upload Invoice" ? (
              ""
            )  
            : name === "Update Invoice" ? (
              ""
            ) : name === "My Profile" ? (
              ""
            ) : (
              <>
                <input
                  name="search "
                  type="search"
                  placeholder="Search"
                  className="search-box"
                  onChange={handleChange}
                />
              </>
            )}
          </div>
          <div className="profile-icon">
            <div
              className="logo fa fa-user-circle cursor-pointer icon logo12"
              onClick={handleClick}
            ></div>
            <label className="user-name">
              Welcome {username && username.split(" ")[0]}
            </label>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className="menuBox"
            >
              <MenuItem className="personalTab">
                <Link to="/profile" className="personal-txt">
                  Personal Information
                </Link>
              </MenuItem>
              <MenuItem className="personalTab" onClick={passwordOpen}>
                Change Password
              </MenuItem>
              <MenuItem className="personalTab" onClick={logout}>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>
      </nav>
      <ChangePassword passOpen={passopen} passClose={passwordClose} />
      {/* <PerosonalInfo
        Open={personalOpen}
        personalClose={personalInfoClose}
      /> */}
    </>
  );
}
