import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Modal, Box, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { addPasswordAction } from "../../action/adminAction";
import showPwdImg from "../images/show-password.svg";
import hidePwdImg from "../images/hide-passwordd.svg";
import "./addUser.css";
import { passwordValidation } from "../middleware/validation";

export default function ChangePassword({ passOpen, passClose }) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    current: "",
    newpassword: "",
    confirmpass: "",
    error: "",
  });

  const handleChange = (e) => {
    setPassword((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const [isCurrent, setIsCurrent] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const resetSubmit = (e) => {
    e.preventDefault();
    if (passwordValidation(password, setPassword)) {
      const passdetails = {
        oldpassword: password.current,
        newpassword: password.newpassword,
      };
      dispatch(addPasswordAction(passdetails, passClose));
    }
  };

  useEffect(() => {
    return () => {
      setPassword({
        current: "",
        newpassword: "",
        confirmpass: "",
        error: "",
      });
    };
  }, [passClose]);

  return (
    <>
      <div>
        <Modal open={passOpen} onClose={passClose}>
          <Box className="changeModal">
            <Typography className="modal-title">
              Change Password
              <div className="border-0 pt-1">
                <hr className="mt-1 bottom_borderColor_overview"></hr>
              </div>
            </Typography>
            <form className="">
              <Row className="change_row">
                <Col lg={12} className="">
                  <label className="label-name12">Enter Current Password</label>
                  <Row className="changP">
                    <div className="pwd-container">
                      <input
                        type={isCurrent ? "text" : "password"}
                        name="current"
                        id="oldpassword"
                        placeholder="Current Password"
                        value={password.current}
                        onChange={handleChange}
                      />
                      <img
                        title={isCurrent ? "Hide password" : "Show password"}
                        src={isCurrent ? hidePwdImg : showPwdImg}
                        alt={isCurrent ? "Hide password" : "Show password"}
                        onClick={() => setIsCurrent((prevState) => !prevState)}
                      />
                    </div>
                    <span className="error-msg">
                      {password && password.error["current"]}
                    </span>
                  </Row>
                </Col>

                <Col lg={12} className="">
                  <label className="label-name12">Enter New Password</label>
                  <Row>
                    <div className="pwd-container">
                      <input
                        type={isNew ? "text" : "password"}
                        name="newpassword"
                        id="newpass"
                        placeholder="New Password"
                        value={password.newpassword}
                        onChange={handleChange}
                      />
                      <img
                        title={isNew ? "Hide password" : "Show password"}
                        src={isNew ? hidePwdImg : showPwdImg}
                        alt={isNew ? "Hide password" : "Show password"}
                        onClick={() => setIsNew((prevState) => !prevState)}
                      />
                    </div>
                    <span className="error-msg">
                      {password && password.error["newpassword"]}
                    </span>
                  </Row>
                </Col>
                <Col lg={12} className="">
                  <label className="label-name12">Enter Confirm Password</label>
                  <Row>
                    <div className="pwd-container">
                      <input
                        type={isRevealPwd ? "text" : "password"}
                        name="confirmpass"
                        id="confirmpass"
                        placeholder="Confirm Password"
                        value={password.confirmpass}
                        onChange={handleChange}
                      />
                      <img
                        title={isRevealPwd ? "Hide password" : "Show password"}
                        src={isRevealPwd ? hidePwdImg : showPwdImg}
                        alt={isRevealPwd ? "Hide password" : "Show password"}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    <span style={{ fontSize: "14px", color: "red" }}>
                      {password && password.error["confirmpass"]}
                    </span>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Button
                  className="AddBtn"
                  size="large"
                  style={{ marginTop: "20px" }}
                  onClick={resetSubmit}
                >
                  Reset Password
                </Button>
                <Button
                  className="CancelBtn"
                  size="large"
                  style={{ marginTop: "20px" }}
                  onClick={passClose}
                >
                  Cancel
                </Button>
              </Row>
            </form>
          </Box>
        </Modal>
      </div>
    </>
  );
}
