import { privateRequest } from "../apiconfig/privateRequest";

export const getservice = async (url, queryParams) => {
  const data = await privateRequest.get(`${url}`, {
    params: queryParams
  });
  return data;
};

export const postservice = async (url, body) => {
  const data = await privateRequest.post(url, body);
  return data;
};

export const putservice = async (url, body) => {
  const data = await privateRequest.put(url, body);
  return data;
};

export const delservice = async (url) => {
  const data = await privateRequest.delete(url);
  return data;
};
