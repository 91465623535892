import React, { useState } from "react";
import Box from '@material-ui/core/Box';
import { Row, Col } from "react-bootstrap";
import Modal from '@material-ui/core/Modal';
import './addUser.css'
import { useDispatch } from "react-redux";
import { addRejectFinancerAction } from "../../action/financerAction";
import { rejectStatusAction } from "../../action/adminAction";


function FinancerReject(props) {
    const dispatch = useDispatch()
    const [msg, setMsg] = useState("")

    const submitMessage = (e) => {
        e.preventDefault();
        // props.handleInvoiceRemove()
        const statu = { status: "rejected", rejectionMsg:msg }
        if(props.admin === 'admin'){
            dispatch(rejectStatusAction(props.rejectno, statu, props?.id))
            props.close()
        }else{
            dispatch(addRejectFinancerAction(props.rejectno, statu))
            props.close()
        }
    }

    return (
        <>
            <Modal open={props.open} onClose={props.close}>
                <Box className="modalclass rejctBox">
                    <div>
                        <span className="modal-title">Reject Message</span>
                    </div>
                    <div>
                        <textarea name="rejectmsg" className="rejectmsg" onChange={e=> setMsg(e.target.value)} required/>
                    </div>
                    <Row className="rejectbtn">
                        <Col md={6}><button className="addReject"onClick={submitMessage}>Submit</button></Col>
                        <Col md={6}><button className="cnclReject" onClick={props.close}>Cancel</button></Col>
                    </Row>
                </Box>
            </Modal>
        </>
    );
}

export default FinancerReject