import { toast } from "react-toastify";
import {
  postservice,
  getservice,
  putservice,
} from "../api/apiservices/service";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  POST_FORGOT_REQUEST,
  POST_FORGOT_SUCCESS,
  POST_FORGOT_FAILED,
  VALIDATE_OTP_REQUEST,
  VALIDATE_OTP_SUCCESS,
  VALIDATE_OTP_FAILD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILD,
  UPDATE_PASSWORD_REQUEST,
} from "../constant/adminConstant/userConstant";
import {
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILED,
  GET_PROFILE_PER_USER_REQUEST,
  GET_PROFILE_PER_USER_SUCCESS,
  GET_PROFILE_PER_USER_FAILED,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
} from "../constant/vendorConstant/vendorConstants";
import {
  FORGOT_PASS,
  PROFILE,
  SIGNIN,
  UPROFILE,
  GOGGLESIGNIN,
  VALIDATE_OTP,
  UPDATE_PASSWORD,
} from "../resources/APIEndpoints";
import { eraseCookie, setCookie } from "../utility";

const LOGIN_USER = "loginUser";

export const signinAction =
  (user, history, rememberMe = false) =>
  async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      const response = await postservice(SIGNIN, user);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("isLoggedIn", response.data.isLoggedIn);
      const profile = await getservice(PROFILE);
      let role = localStorage.getItem("role");
      dispatch({ type: LOGIN_SUCCESS, payload: response.data });
      if (rememberMe) {
        eraseCookie(LOGIN_USER);
        setCookie(LOGIN_USER, `${user.email}$${user.password}`, 1);
      }

      if (
        (profile.data.profile.gstNumber === null &&
          profile.data.profile.panNumber === null &&
          role === "vendor") ||
        (role === "vendor" &&
          profile.data.profile.vendor.bankAccountNumber === null &&
          profile.data.profile.vendor.ifscCode === null)
      ) {
        history.push("/profile");
      } else {
        history.push("/dashboard");
      }
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: LOGIN_FAILED, payload: error });
    }
  };

export const signinGoogleAction = (token, history) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });
  try {
    const response = await postservice(GOGGLESIGNIN, token);
    localStorage.setItem("name", response.data.name);
    localStorage.setItem("role", response.data.role);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("isLoggedIn", response.data.isLoggedIn);
    const profile = await getservice(PROFILE);
    let role = localStorage.getItem("role");
    dispatch({ type: LOGIN_SUCCESS, payload: response.data });

    if (
      profile.data.profile.gstnumber === null &&
      profile.data.profile.pannumber === null &&
      role === "vendor"
    ) {
      history.push("/profile");
    } else {
      history.push("/dashboard");
    }
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: LOGIN_FAILED, payload: error });
  }
};

//Profile
export const getProfileAction = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_REQUEST });
  try {
    const response = await getservice(PROFILE);

    dispatch({ type: GET_PROFILE_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_PROFILE_FAILED, payload: error });
  }
};

//profile per user
export const getProfileActionPerUser = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_PER_USER_REQUEST });
  try {
    const response = await getservice(PROFILE);

    dispatch({ type: GET_PROFILE_PER_USER_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_PROFILE_PER_USER_FAILED, payload: error });
  }
};

//update Profile
export const updateProfileAction = (data, history) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_REQUEST });
  try {
    const response = await putservice(UPROFILE + `/${data.userId}`, data);
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: response.data });
    toast.success("Profile Updated");
    history.push("/dashboard");
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_FAILED, payload: error });
  }
};

//Forgot Password
export const addForgotPassAction = (email,history) => async (dispatch) => {
  dispatch({ type: POST_FORGOT_REQUEST });
  try {
    const response = await postservice(FORGOT_PASS, email);
    dispatch({ type: POST_FORGOT_SUCCESS, payload: response.data });
    toast.success(response.data.message);
     history.push("/submitotp");
  } catch (error) {
    // toast.error("Something went wrong");
    dispatch({ type: POST_FORGOT_FAILED, payload: error });
  }
};

//VALIDATE OTP
export const validateOtp = (data,history) => async (dispatch) => {
  dispatch({ type: VALIDATE_OTP_REQUEST });
  try {
    const res = await postservice(VALIDATE_OTP, data);
    dispatch({ type: VALIDATE_OTP_SUCCESS, payload: res.data });
    toast.success(res.data.message);
    if(res.data.message === "otp verified successfully"){
      history.push("/newpassword")
    }

  } catch (error) {
    dispatch({ type: VALIDATE_OTP_FAILD, payload: error });
  }
};

//Update Password
export const updatePassword = (data,history) => async (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_REQUEST });
  try {
    const res = await postservice(UPDATE_PASSWORD, data);
    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: res.data });
    toast.success(res.data.message);
    if(res.data.message === "Password Updated."){
      history.push("/")
    }

  } catch (error) {
    dispatch({ type: UPDATE_PASSWORD_FAILD, payload: error });
  }
};
