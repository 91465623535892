import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../components/reusable/PrivateRoute";
import PublicRoute from "../components/reusable/PublicRoute";
import Loader from "../components/loader/Loader";
import BankData from "../components/adminDashboard/bankData";
import InvoiceTrails from "../components/adminDashboard/InvoiceTrails";

const Login = lazy(() => import("../components/Login"));
const AdminDashboard = lazy(() =>
  import("../components/adminDashboard/adminDashboard")
);
const Admin = lazy(() => import("../components/adminDashboard/admin"));

const AdminInvoices = lazy(() =>
  import("../components/adminDashboard/adminInvoices")
);
const ApproverAdmin = lazy(() =>
  import("../components/adminDashboard/approverAdmin")
);
const FinanceAdmin = lazy(() =>
  import("../components/adminDashboard/financeAdmin")
);
const VendorAdmin = lazy(() =>
  import("../components/adminDashboard/vendorAdmin")
);
const VendorRequest = lazy(() =>
  import("../components/adminDashboard/vendorRequest")
);
const VendoruserAdmin = lazy(() =>
  import("../components/adminDashboard/vendoruserAdmin")
);
const FinancerAdminUser = lazy(() =>
  import("../components/adminDashboard/financerAdminUser")
);
const AdminApproverList = lazy(() =>
  import("../components/adminDashboard/adminApproverList")
);
const VendorAdminList = lazy(() =>
  import("../components/adminDashboard/vendorAdminList")
);
const VendorUserList = lazy(() =>
  import("../components/adminDashboard/vendorUserList")
);
const UserList = lazy(() => import("../components/adminDashboard/userList"));
const Permission = lazy(() =>
  import("../components/adminDashboard/permission")
);
const AdminProfile = lazy(() =>
  import("../components/adminDashboard/adminProfile")
);
const AdminCreateinv = lazy(() =>
  import("../components/adminDashboard/adminCreateinv")
);
const ArchivedInvoices = lazy(() =>
  import("../components/adminDashboard/archivedInvoices")
);
const ArchivedAdminBulkPayment = lazy(() =>
  import("../components/adminDashboard/ArchivedAdminBulkPayment")
);
// const ArchivedBulkUploadInvoices = lazy(() =>
//   import("../components/adminDashboard/ArchivedBulkUploadInvoices")
// );
const AdminUpcomingOverdue = lazy(() =>
  import("../components/adminDashboard/AdminUpcomingOverdue")
);
const UpdateInvoice = lazy(() =>
  import("../components/adminDashboard/updateInvoice.js")
);

const FinancerAdminBulkPayment = lazy(() =>
  import ("../components/adminDashboard/financerAdminBulkPayment")
);

const AdminRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={AdminDashboard} />
        <PrivateRoute exact path="/invoicetrails" component={InvoiceTrails} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute
          exact
          path="/admin/invoices/:id"
          component={AdminInvoices}
        />
        <PrivateRoute exact path="/admin/vendor" component={VendorAdmin} />
        <PrivateRoute
          exact
          path="/admin/vendor-request"
          component={VendorRequest}
        />
        <PrivateRoute exact path="/admin/approver" component={ApproverAdmin} />
        <PrivateRoute exact path="/financeAdmin" component={FinanceAdmin} />
        <PrivateRoute
          exact
          path="/admin/vendor-list/:id"
          component={VendorAdminList}
        />
        <PrivateRoute
          exact
          path="/admin/edit-invoice/:id"
          component={UpdateInvoice}
        />
        <PrivateRoute
          exact
          path="/admin/vendoruser/:id"
          component={VendoruserAdmin}
        />
        <PrivateRoute
          exact
          path="/admin/vendoruser-list/:id"
          component={VendorUserList}
        />
        <PrivateRoute
          exact
          path="/admin/approver-list/:id"
          component={AdminApproverList}
        />
        <PrivateRoute
          exact
          path="/admin/financer-user/:id"
          component={FinancerAdminUser}
        />
        <PrivateRoute
          exact
          path="/admin/create-invoice"
          component={AdminCreateinv}
        />
        <PrivateRoute
          exact
          path="/admin/upcoming-overdue"
          component={AdminUpcomingOverdue}
        />
        <PrivateRoute
          exact
          path="/admin/archived-invoice"
          component={ArchivedInvoices}
        />
        <PrivateRoute
          exact
          path="/admin/bulk-upload/archived-invoice"
          component={ArchivedAdminBulkPayment}
        />
         {/* <PrivateRoute
          exact
          path="/admin/bulk-upload/archived-invoice"
          component={ArchivedBulkUploadInvoices}
        /> */}
        <PrivateRoute exact path="/admin/users" component={UserList} />
        <PrivateRoute
          exact
          path="/admin/permission/:id"
          component={Permission}
        />
        <PrivateRoute
          exact
          path="/admin/bulk-invoice-payment/:id"
          component={FinancerAdminBulkPayment}
        />
        <PrivateRoute
          exact
          path="/admin/bank-data"
          component={BankData}
        />
        <PrivateRoute exact path="/profile" component={AdminProfile} />
        <PublicRoute exact path="/" component={Login} />
      </Switch>
    </Suspense>
  );
};

export default AdminRoutes;
