import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";

import Loader from "../components/loader/Loader";

import PrivateRoute from "../components/reusable/PrivateRoute";
import PublicRoute from "../components/reusable/PublicRoute";

const Login = lazy(() => import("../components/Login"));
const ApproverDashboard = lazy(() =>
  import("../components/approver/approverDashboard")
);
const VendorList = lazy(() => import("../components/approver/vendorList"));
// const VendorRequest = lazy(() => import('../components/approver/vendorRequest'))
const ApproverInvoice = lazy(() =>
  import("../components/approver/approverInvoice")
);
const ApproverProfile = lazy(() =>
  import("../components/approver/approverProfile")
);
const ApproverUpcomingOverdue = lazy(() =>
  import("../components/approver/ApproverUpcomingOverdue")
);
const ApproverVendorListUser = lazy(() =>
  import("../components/approver/ApproverVendorListUser")
);
const VendorRequest = lazy(() =>
  import("../components/approver/approverVendorRequest")
);

const ApproverRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={ApproverDashboard} />
        <PrivateRoute
          exact
          path="/approver/vendor-list"
          component={VendorList}
        />
        <PrivateRoute
          exact
          path="/approver/vendor-request"
          component={VendorRequest}
        />
        <PrivateRoute
          exact
          path="/approver/vendor-list/:id"
          component={ApproverVendorListUser}
        />
        <PrivateRoute
          exact
          path="/approver/invoice-list"
          component={ApproverInvoice}
        />
        <PrivateRoute
          exact
          path="/approver/upcoming-overdue"
          component={ApproverUpcomingOverdue}
        />
        <PrivateRoute exact path="/profile" component={ApproverProfile} />
        <PrivateRoute
          exact
          path="/approver/vendor-request"
          component={VendorRequest}
        />
        <PublicRoute exact path="/" component={Login} />
      </Switch>
    </Suspense>
  );
};
export default ApproverRoutes;
