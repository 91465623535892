import React, { useEffect, useMemo, useState } from "react";
import "../finance/financeDashboard.css";
// import { useHistory } from "react-router-dom";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewInvoice from "../modal/viewInvoice";
import Navbar from "../navbar/navbar";
import SideBar from "../sideBar/adminsidebars";
import { useLocation, useParams } from "react-router-dom";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import FinancerReject from "../modal/financerReject";
import TableContainer from "@material-ui/core/TableContainer";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Pagination from "../Pagination";
import { delservice, getservice } from "../../api/apiservices/service";

import { DELETE_BANK_DATA, GET_BANK_DATA } from "../../resources/APIEndpoints";
import { toast } from "react-toastify";
import AddBankData from "../modal/addBankData";
import delicon from "../images/deleteicon.png";
import { ReactComponent as Editicon } from "../images/edit.svg";
import ConfirmDeleteModal from "../confirmDeleteModal";

export default function BankData(props) {
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  //Pagination
  const [pageNumber, setPageNumber] = useState(0);

  const [bankCsvData, setBankCsvData] = useState([]);
  const [loadData, setLoadData] = useState(null);

  const [perpage, setPerpage] = useState(10);
  const pageVisited = pageNumber * perpage;
  const pageCount = Math.ceil(bankCsvData ? bankCsvData.length / perpage : 0);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setLoadData(null);
  };

  const [mobileOpen, setMobileOpen] = useState(true);
  const [alag, setAlag] = useState("pro-finance-partition");
  const [sidestyle, setSidestyle] = useState("sideopen");

  const [delmodal, setDelmodal] = useState(false);
  const [id, setId] = useState();
  const handleDelete = (id) => {
    setId(id);
    setDelmodal(true);
  };

  const deleteClose = () => {
    setId("");
    setDelmodal(false);
  };
  const toggle = () => {
    if (mobileOpen === true) {
      setMobileOpen(false);
      setAlag("finance-partition");
      setSidestyle("sideopen");
    } else {
      setMobileOpen(true);
      setAlag("pro-finance-partition");
      setSidestyle("sideclose");
    }
  };

  const currentPageData = useMemo(() => {
    const lastCardIndex = perpage * (pageNumber + 1);
    const firstCardIndex = lastCardIndex - perpage;
    return bankCsvData
      ? bankCsvData?.slice(firstCardIndex, lastCardIndex)
      : null;
  }, [pageNumber, bankCsvData, perpage]);

  const handleShow = () => {
    setShow(true);
  };

  const getBankData = async () => {
    try {
      const res = await getservice(GET_BANK_DATA);
      setBankCsvData(res?.data?.result);
    } catch (e) {
      setBankCsvData([]);
    }
  };

  const deleteBank = async (id) => {
    try {
      const res = await delservice(`${DELETE_BANK_DATA}/${id}`);
      getBankData();
      toast.success(res?.data?.message || "Bank Deleted Successfully");
    } catch (e) {}
  };

  useEffect(() => {
    getBankData();
  }, []);

  return (
    <>
      <Navbar
        mobileOpen={mobileOpen}
        toggle={toggle}
        name={"Bank Data"}
        setSearch={setSearch}
        search={search}
      />
      <SideBar
        mobileOpen={mobileOpen}
        stylesname={sidestyle}
        toggle={toggle}
        name={"Bank Data"}
      />
      <TableContainer className={alag}>
        <div className="invoice-bottom-table">
          <div className="mx-2 boxborder main-table align_left">
            <Row className="title-filter">
              {/* <Col className="titlecol back-styles">
                        <span className="back-styles"
                        >
                          <ArrowBackIcon className="arrow-back-icon"/>
                        </span>
                      </Col> */}
              <Col xs={5}></Col>

              <Col className="btncol">
                <button className="addEditbtn " onClick={() => handleShow()}>
                  Add
                </button>
              </Col>
            </Row>

            <div className="table-responsive mt-3">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Bank Name</th>
                    <th>Account Number</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData?.length ? (
                    currentPageData.map((data) => {
                      return (
                        <tr key={data.id}>
                          <td>
                            {data.bank_name
                              ? data.bank_name?.toUpperCase()
                              : "--"}
                          </td>
                          <td>
                            {data.account_number}
                            {/* <a href={data.csv} target="_blank" rel="noopener noreferrer">
                                                    {data.csv}
                                                </a> */}
                          </td>
                          <td className="table-link">
                            <Editicon
                              className="edit-icon"
                              onClick={() => {
                                setLoadData(data);
                                setShow(true);
                              }}
                            />
                            <span onClick={() => {}}>
                              <img
                                src={delicon}
                                alt="delete icon"
                                className="delicon"
                                onClick={() => handleDelete(data.id)}
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="notFound">
                      <div className="noUserfound">No Data Found</div>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="pageContainer">
              <Pagination
                count={pageCount}
                // newChecked={newChecked}
                setPerpage={setPerpage}
                setPageNumber={setPageNumber}
                perpage={perpage}
              />
            </div>
          </div>
        </div>
      </TableContainer>
      {show ? (
        <AddBankData
          open={show}
          close={handleClose}
          bankCsvData={bankCsvData}
          loadData={loadData}
          getBankData={getBankData}
          // type={type}
          // data={userdata}
          // fetchData={fetchData}
        />
      ) : (
        ""
      )}
      {delmodal ? (
        <ConfirmDeleteModal
          id={id}
          title={"Bank Data"}
          open={delmodal}
          close={deleteClose}
          handleDelete={deleteBank}
        />
      ) : (
        ""
      )}
    </>
  );
}
