import React, { lazy, Suspense, useEffect } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../components/reusable/PrivateRoute";
import PublicRoute from "../components/reusable/PublicRoute";

import Loader from "../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getProfileAction } from "../action/userAction";

const Login = lazy(() => import("../components/Login"));
const VendorDashboard = lazy(() =>
  import("../components/vendors/vendorDashboard")
);
const UserManagement = lazy(() =>
  import("../components/vendors/userManagement")
);
const InvoiceManagement = lazy(() =>
  import("../components/vendors/invoiceManagement")
);
const Createinvoice = lazy(() => import("../components/vendors/createinvoice"));
const UpdateInvoice = lazy(() => import("../components/vendors/updateInvoice"));
const VendorProfile = lazy(() => import("../components/vendors/vendorProfile"));
const UpcomingOverdue = lazy(() =>
  import("../components/vendors/UpcomingOverdue")
);

const VendorRoutes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //dispatch(getProfileAction());
  }, []);
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={VendorDashboard} />
        <PrivateRoute
          exact
          path="/vendor/user-management"
          component={UserManagement}
        />
        <PrivateRoute
          exact
          path="/vendor/invoice-management"
          component={InvoiceManagement}
        />
        <PrivateRoute
          exact
          path="/vendor/upcoming-overdue"
          component={UpcomingOverdue}
        />
        <PrivateRoute
          exact
          path="/vendor/create-invoice"
          component={Createinvoice}
        />
        <PrivateRoute
          exact
          path="/vendor/edit-invoice/:id"
          component={UpdateInvoice}
        />
        <PrivateRoute exact path="/profile" component={VendorProfile} />
        <PublicRoute exact path="/" component={Login} />
      </Switch>
    </Suspense>
  );
};
export default VendorRoutes;
