import * as userType from "../constant/adminConstant/userConstant";
import * as adminType from "../constant/adminConstant/adminConstant";
//admin crud
export const addAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case adminType.ADD_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminType.ADD_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adminAdd: action.payload,
      };
    case adminType.ADD_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case adminType.EDIT_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminType.EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adminEdit: action.payload,
      };
    case adminType.EDIT_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const deleteAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case adminType.DELETE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case adminType.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adminDel: action.payload,
      };
    case adminType.DELETE_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getAdminReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        getAdmin: action.payload,
      };
    case userType.GET_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const getInvoiceDetailsListReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_INVOICE_DETAILS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_INVOICE_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoiceDetailsList: action.payload,
      };
    case userType.GET_INVOICE_DETAILS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        invoiceDetailsList: {},
      };
    default:
      return state;
  }
};

//upcoming overdue
export const getAdminUpcomingOverdueReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        getOverdue: action.payload,
      };
    case userType.GET_ADMIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const addUserReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        adduser: action.payload,
      };
    case userType.ADD_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userinfo: action.payload,
      };
    case userType.GET_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editUserReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.EDIT_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.EDIT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userEdit: action.payload,
      };
    case userType.EDIT_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vduser: action.payload,
      };
    case userType.DELETE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getDepartmentReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        department: action.payload,
      };
    case userType.GET_DEPARTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.GET_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.GET_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        userfilter: action.payload,
      };
    case userType.GET_FILTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//CHANGE PASSWORD
export const addPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case userType.ADD_CHNGPASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userType.ADD_CHNGPASS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        changepass: action.payload,
      };
    case userType.ADD_CHNGPASS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
