import { toast } from "react-toastify";
import { getservice, putservice } from "../api/apiservices/service";
import * as finType from "../constant/financer/financerConstants";
import * as finType2 from "../constant/adminConstant/financerConstant";
import {
  ARCHIVE,
  FINANCER_DASH,
  FINANCER_INV,
  FINANCER_PAID,
  FINANCER_STATUS,
  GET_ARCHIVE,
  UNARCHIVE,
  FINANCER_DASH_OVERVIEW,
  FINANCER_OVERDUE_LIST,
  APP_FINANCER_PAY_PENDING_LIST,
  APP_FINANCER_PAY_PAID_LIST,
  APP_FINANCER_PAY_PARTIAL_PAID_LIST,
  APP_FINANCER_PAY_REJECTED_LIST,
} from "../resources/APIEndpoints";

// Financer Dashboard
export const getFinancerorgAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_FINANCERS_REQUEST });
    try {
      const response = await getservice(
        FINANCER_DASH + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_FINANCERS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_FINANCERS_FAILED, payload: error });
    }
  };

//Get financer dashboard overview
export const getFinancerOverviewAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_FINANCERS_OVERVIEW_REQUEST });
    try {
      const response = await getservice(
        FINANCER_DASH_OVERVIEW +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({
        type: finType.GET_FINANCERS_OVERVIEW_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: finType.GET_FINANCERS_OVERVIEW_FAILED, payload: error });
    }
  };

//get upcoming overdue-list
export const getFinancerUpcomingOverdueAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_FINANCERS_REQUEST });
    try {
      const response = await getservice(
        FINANCER_OVERDUE_LIST +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_FINANCERS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_FINANCERS_FAILED, payload: error });
    }
  };

//Get Invoice
export const getOverdueAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_OVERDUE_REQUEST });
    try {
      const response = await getservice(
        FINANCER_INV +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_OVERDUE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_OVERDUE_FAILED, payload: error });
    }
  };

//get paid panel details
export const getPaidInvAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_PAIDINV_REQUEST });
    try {
      const response = await getservice(
        FINANCER_PAID +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_PAIDINV_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_PAIDINV_FAILED, payload: error });
    }
  };

//invoice update Status
export const updateStatusAction =
  ({ invoiceno, status, history }) =>
  async (dispatch) => {
    dispatch({ type: finType.UPDATE_STATUS_REQUEST });
    try {
      const response = await putservice(FINANCER_STATUS + `/${invoiceno}`, {
        status,
      });
      dispatch({ type: finType.UPDATE_STATUS_SUCCESS, payload: response.data });
      history.push("/financer/paid-invoice");
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: finType.UPDATE_STATUS_FAILED, payload: error });
    }
  };
//Rejection
export const addRejectFinancerAction =
  (invoiceno, status) => async (dispatch) => {
    dispatch({ type: finType.ADD_REJECT_REQUEST });
    try {
      const response = await putservice(
        FINANCER_STATUS + `/${invoiceno}`,
        status
      );
      dispatch({ type: finType.ADD_REJECT_SUCCESS, payload: response.data });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: finType.ADD_REJECT_FAILED, payload: error });
    }
  };

export const getPaidDueDateInvAction =
  (ascdsc, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_PAIDINV_REQUEST });
    try {
      const response = await getservice(
        FINANCER_PAID + `?sort=due_date&orderby=${ascdsc}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_PAIDINV_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_PAIDINV_FAILED, payload: error });
    }
  };

export const getPaidAmountInvAction =
  (ascdsc, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: finType.GET_PAIDINV_REQUEST });
    try {
      const response = await getservice(
        FINANCER_PAID + `?sort=amount&orderby=${ascdsc}&from=${from}&to=${to}`
      );
      dispatch({ type: finType.GET_PAIDINV_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.GET_PAIDINV_FAILED, payload: error });
    }
  };

//Get archive list
export const getarchivelistAction =
  (search, sortBy, sortOrder, from, to = "1", startDate, endDate) =>
  async (dispatch) => {
    dispatch({ type: finType.ARCHIVELIST_REQUEST });
    try {
      const response = await getservice(
        GET_ARCHIVE +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}&startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({ type: finType.ARCHIVELIST_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: finType.ARCHIVELIST_FAILED, payload: error });
    }
  };
//Get archive list
export const archiveAction = (id) => async (dispatch) => {
  dispatch({ type: finType.ARCHIVE_REQUEST });
  try {
    const response = await putservice(ARCHIVE + `/${id}`);
    dispatch({ type: finType.ARCHIVE_SUCCESS, payload: response.data });
    toast.success("Invoice Archived");
  } catch (error) {
    dispatch({ type: finType.ARCHIVE_FAILED, payload: error });
  }
};
//Get archive list
export const unarchiveAction = (id) => async (dispatch) => {
  dispatch({ type: finType.UNARCHIVE_REQUEST });
  try {
    const response = await putservice(UNARCHIVE + `/${id}`);
    dispatch({ type: finType.UNARCHIVE_SUCCESS, payload: response.data });
    toast.success("Invoice Restored Successfully");
  } catch (error) {
    dispatch({ type: finType.UNARCHIVE_FAILED, payload: error });
  }
};
// pending action

export const getfinancerPaymentPendingAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: finType2.FINANCER_PAYMENT_PENDING__REQUEST });
    try {
      const response = await getservice(
        APP_FINANCER_PAY_PENDING_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );

      dispatch({
        type: finType2.FINANCER_PAYMENT_PENDING__SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: finType2.FINANCER_PAYMENT_PENDING__FAILED,
        payload: error,
      });
    }
  };

export const getfinancerPaidAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: finType2.FINANCER_PAID_REQUEST });
    try {
      const response = await getservice(
        APP_FINANCER_PAY_PAID_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: finType2.FINANCER_PAID__SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: finType2.FINANCER_PAID__FAILED,
        payload: error,
      });
    }
  };
export const getfinancerPartialPaymentAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: finType2.FINANCER_PAYMENT_PARTIAL_REQUEST });
    try {
      const response = await getservice(
        APP_FINANCER_PAY_PARTIAL_PAID_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: finType2.FINANCER_PAYMENT_PARTIAL_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: finType2.FINANCER_PAYMENT_PARTIAL_FAILED,
        payload: error,
      });
    }
  };
export const getfinancerRejectedAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: finType2.REJECT_PAYMENT_PAID_REQUEST });
    try {
      const response = await getservice(
        APP_FINANCER_PAY_REJECTED_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: finType2.REJECT_PAYMENT_PAID_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: finType2.REJECT_PAYMENT_PAID_FAILED,
        payload: error,
      });
    }
  };

  export const selectedInvoices = (invoices) => {
    return {
      type: finType.BULK_PAYMENT_INVOICE_LIST,
      payload: invoices,
    }
  }

  export const removeFromSelectedInvoice = (invoice) => {
    return {
      type: finType.REMOVE_FROM_BULK_PAYMENT_INVOICE_LIST,
      payload : invoice,
    }
  }

  export const emptySelectedInvoice = () => {
    return {
      type: finType.EMPTY_SELECETED_INVOICE_LIST,
    }
  }

  export const updateFormikData = (values) => {
    return {
      type: finType.UPDATE_FORMIK_DATA,
      payload: values
    }
  }

  export const removeFromFormikData = (invoiceno) => {
    return {
      type: finType.REMOVE_FROM_FORMIK_DATA,
      payload: invoiceno
    }
  }

  export const clearFormikData = () => {
    return {
      type: finType.CLEAR_FORMIK_DATA,
    }
  }