import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "../components/LoginPass.css";
// import image from "../../assets/Corps.png";
import background from './images/Login.png';

export default function SendOtp(){
  const [input, setInput] = useState("");
  const [passInput, setPassInput] = useState("");

  const history = useHistory();
  const handleOtp = () => {
    if (input !== "") {
      history.push("/");
    }
  }
  return (
    <>
    <div className="background img " style={{ backgroundImage: `url(${background})` }}>
      <div className="login-1 col-md-8">
        <img
          src="https://nimapinfotech.com/wp-content/uploads/2019/01/nimap-logo.png"
          alt=""
        />
        <h3
          style={{
            fontSize: "40px",
            marginTop: "15px",
            color: "#fff",
          }}
        >
          Invoice <span className="onBench">Management</span>
        </h3>
        <h6 style={{ color: "#fff" }}>Forgot Password</h6>
        <form className="form">
          <div className="form-group w-100">
            <label style={{ color: "#fff" }}>Password</label>
            <input
              type="password"
              className="form-control form-control-lg"
              placeholder="Enter Password"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              required
            />
          </div>
          <div className="form-group w-100">
            <label style={{ color: "#fff" }}>Confirm Password</label>
            <input
              type="password"
              className="form-control form-control-lg"
              placeholder="Confirm Password"
              value={passInput}
              onChange={(e) => setPassInput(e.target.value)}
              required
            />
          </div>
          {/* <div className="form-group w-100">
            <div>
              <label style={{ color: "#fff" }}>Enter OTP</label>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <input type="text" className="specs" maxLength="1" required />
              <input type="text" className="specs" maxLength="1" required />
              <input type="text" className="specs" maxLength="1" required />
              <input type="text" className="specs" maxLength="1" required />
              <input type="text" className="specs" maxLength="1" required />
              <input type="text" className="specs" maxLength="1" required />
            </div>
          </div> */}

          <div className="form-group w-100">
            <button
              type="submit"
              className="btn w-100"
              onClick={handleOtp}
              style={{ backgroundColor: "#64bcec", color: "#fff" }}
            >
              Submit
            </button>
          </div>
          {/* <p style={{ color: "#fff" }}>
            OTP has been sent to your registered email id
          </p>
          <div className="forgot">
            <label style={{ color: "#fff" }}>Didn't get OTP?&nbsp;</label>
            <Link to="/" style={{ color: "#6aacee" }}>
              Resend OTP
            </Link>
          </div> */}
        </form>
      </div>
    </div>
    </>
  );
}
