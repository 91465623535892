import {
  GET_SEARCHFIELD_FAILED,
  GET_SEARCHFIELD_REQUEST,
} from "../adminConstant/vendorConstant";

export const CREATE_INVOICE_REQUEST = "CREATE_INVOICE_REQUEST";
export const CREATE_INVOICE_SUCCESS = "CREATE_INVOICE_SUCCESS";
export const CREATE_INVOICE_FAILED = "CREATE_INVOICE_FAILED";

export const EDIT_INVOICE_REQUEST = "EDIT_INVOICE_REQUEST";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAILED = "EDIT_INVOICE_FAILED";

//create invoice modal
export const GET_INVOICEDATA_REQUEST = "GET_INVOICEDATA_REQUEST";
export const GET_INVOICEDATA_SUCCESS = "GET_INVOICEDATA_SUCCESS";
export const GET_INVOICEDATA_FAILED = "GET_INVOICEDATA_FAILED";

//invoice management
export const GET_INVOICEMNG_REQUEST = "GET_INVOICEMNG_REQUEST";
export const GET_INVOICEMNG_SUCCESS = "GET_INVOICEMNG_SUCCESS";
export const GET_INVOICEMNG_FAILED = "GET_INVOICEMNG_FAILED";

export const GET_VIEWINVOICE_REQUEST = "GET_VIEWINVOICE_REQUEST";
export const GET_VIEWINVOICE_SUCCESS = "GET_VIEWINVOICE_SUCCESS";
export const GET_VIEWINVOICE_FAILED = "GET_VIEWINVOICE_FAILED";

export const DELETE_INVOICEMNG_REQUEST = "DELETE_INVOICEMNG_REQUEST";
export const DELETE_INVOICEMNG_SUCCESS = "DELETE_INVOICEMNG_SUCCESS";
export const DELETE_INVOICEMNG_FAILED = "DELETE_INVOICEMNG_FAILED";

//filter invoice management
export const GET_INVFILTER_REQUEST = "GET_INVFILTER_REQUEST";
export const GET_INVFILTER_SUCCESS = "GET_INVFILTER_SUCCESS";
export const GET_INVFILTER_FAILED = "GET_INVFILTER_FAILED";

export const ADD_FILE_REQUEST = "ADD_FILE_REQUEST";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";
export const ADD_FILE_FAILED = "ADD_FILE_FAILED";

export const UPDATE_FILE_REQUEST = "UPDATE_FILE_REQUEST";
export const UPDATE_FILE_SUCCESS = "UPDATE_FILE_SUCCESS";
export const UPDATE_FILE_FAILED = "UPDATE_FILE_FAILED";

export const GST_TDS_REQUEST = "GST_TDS_REQUEST";
export const GST_TDS_SUCCESS = "GST_TDS_SUCCESS";
export const GST_TDS_FAILED = "GST_TDS_FAILED";

export const INVDETAILS_REQUEST = "INVDETAILS_REQUEST";
export const INVDETAILS_SUCCESS = "INVDETAILS_SUCCESS";
export const INVDETAILS_FAILED = "INVDETAILS_FAILED";

//SEARCH
export const GET_INVOICEMNGSEARCH_REQUEST = "INVOICEMNGSEARCH_REQUEST";
export const GET_INVOICEMNGSEARCH_SUCCESS = "GET_INVOICEMNGSEARCH_SUCCESS";
export const GET_INVOICEMNGSEARCH_FAILED = "GET_INVOICEMNGSEARCH_FAILED";

export const PROFILE_FILE_REQUEST = "PROFILE_FILE_REQUEST";
export const PROFILE_FILE_FAILED = "PROFILE_FILE_FAILED";
