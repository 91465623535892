import * as userMng from "../../constant/vendorConstant/usermanagement";

export const addVendoruserReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.ADD_VENDORUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.ADD_VENDORUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addvenuser: action.payload,
      };
    case userMng.ADD_VENDORUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendoruserReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.GET_VENDORUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.GET_VENDORUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendoruser: action.payload,
      };
    case userMng.GET_VENDORUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editVendoruserReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.EDIT_VENDORUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.EDIT_VENDORUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        vendorinfo: action.payload,
      };
    case userMng.EDIT_VENDORUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteVendoruserReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.DELETE_VENDORUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.DELETE_VENDORUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        deleteuser: action.payload,
      };
    case userMng.DELETE_VENDORUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getInvoiceByUserReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.GET_INVBYUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.GET_INVBYUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoiceByUser: action.payload,
      };
    case userMng.GET_INVBYUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getReducer = (state = {}, action) => {
  switch (action.type) {
    case userMng.GET_INVBYUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.GET_INVBYUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        invoiceByUser: action.payload,
      };
    case userMng.GET_INVBYUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getVendorUserSearchAction = (state = {}, action) => {
  switch (action.type) {
    case userMng.GET_USERMNGSEARCH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userMng.GET_USERMNGSEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        searchfield: action.payload,
      };
    case userMng.GET_USERMNGSEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
