export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

//Admin user
export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED";

export const GET_INVOICE_DETAILS_LIST_REQUEST =
  "GET_INVOICE_DETAILS_LIST_REQUEST";
export const GET_INVOICE_DETAILS_LIST_FAILED =
  "GET_INVOICE_DETAILS_LIST_FAILED";
export const GET_INVOICE_DETAILS_LIST_SUCCESS =
  "GET_INVOICE_DETAILS_LIST_SUCCESS";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";

export const EDIT_USER_REQUEST = "EDIT_USER_REQUEST";
export const EDIT_USER_FAILED = "EDIT_USER_FAILED";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

//Department
export const GET_DEPARTMENT_REQUEST = "GET_DEPARTMENT_REQUEST";
export const GET_DEPARTMENT_FAILED = "GET_DEPARTMENT_FAILED";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";

//Filter
export const GET_FILTER_REQUEST = "GET_FILTER_REQUEST";
export const GET_FILTER_FAILED = "GET_FILTER_FAILED";
export const GET_FILTER_SUCCESS = "GET_FILTER_SUCCESS";

//change pass
export const ADD_CHNGPASS_REQUEST = "ADD_CHNGPASS_REQUEST";
export const ADD_CHNGPASS_FAILED = "ADD_CHNGPASS_FAILED";
export const ADD_CHNGPASS_SUCCESS = "ADD_CHNGPASS_SUCCESS";

//forgot Password
export const POST_FORGOT_REQUEST = "POST_FORGOT_REQUEST";
export const POST_FORGOT_FAILED = "POST_FORGOT_FAILED";
export const POST_FORGOT_SUCCESS = "POST_FORGOT_SUCCESS";

//validate otp
export const VALIDATE_OTP_REQUEST = "VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILD = "VALIDATE_OTP_FAILD";

//update password

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILD = "UPDATE_PASSWORD_FAILD";

export const UPDATE_PROFILE_INFO_REQUEST = "UPDATE_PROFILE_INFO_REQUEST";
export const UPDATE_PROFILE_INFO_SUCCESS = "UPDATE_PROFILE_INFO_SUCCESS";
export const UPDATE_PROFILE_INFO_FAILED = "UPDATE_PROFILE_INFO_FAILED";

export const GET_BANK_REQUEST = "GET_BANK_REQUEST";
export const GET_BANK_SUCCESS = "GET_BANK_SUCCESS";
export const GET_BANK_FAILED = "GET_BANK_FAILED";
