export const ADD_FINANCER_REQUEST = "ADD_FINANCER_REQUEST";
export const ADD_FINANCER_SUCCESS = "ADD_FINANCER_SUCCESS";
export const ADD_FINANCER_FAILED = "ADD_FINANCER_FAILED";

export const GET_FINANCER_REQUEST = "GET_FINANCER_REQUEST";
export const GET_FINANCER_SUCCESS = "GET_FINANCER_SUCCESS";
export const GET_FINANCER_FAILED = "GET_FINANCER_FAILED";

export const GET_FINANCERS_OVERVIEW_REQUEST = "GET_FINANCERS_OVERVIEW_REQUEST";
export const GET_FINANCERS_OVERVIEW_SUCCESS = "GET_FINANCERS_OVERVIEW_SUCCESS";
export const GET_FINANCERS_OVERVIEW_FAILED = "GET_FINANCERS_OVERVIEW_FAILED";

export const EDIT_FINANCER_REQUEST = "EDIT_FINANCER_REQUEST";
export const EDIT_FINANCER_SUCCESS = "EDIT_FINANCER_SUCCESS";
export const EDIT_FINANCER_FAILED = "EDIT_FINANCER_FAILED";

export const DELETE_FINANCER_REQUEST = "DELETE_FINANCER_REQUEST";
export const DELETE_FINANCER_SUCCESS = "DELETE_FINANCER_SUCCESS";
export const DELETE_FINANCER_FAILED = "DELETE_FINANCER_FAILED";

export const GET_ALLFINANCER_REQUEST = "GET_ALLFINANCER_REQUEST";
export const GET_ALLFINANCER_SUCCESS = "GET_ALLFINANCER_SUCCESS";
export const GET_ALLFINANCER_FAILED = "GET_ALLFINANCER_FAILED";

export const GET_PAIDFINANCER_REQUEST = "GET_PAIDFINANCER_REQUEST";
export const GET_PAIDFINANCER_SUCCESS = "GET_PAIDFINANCER_SUCCESS";
export const GET_PAIDFINANCER_FAILED = "GET_PAIDFINANCER_FAILED";

export const GET_PARTIALFINANCER_REQUEST = "GET_PARTIALFINANCER_REQUEST";
export const GET_PARTIALFINANCER_SUCCESS = "GET_PARTIALFINANCER_SUCCESS";
export const GET_PARTIALFINANCER_FAILED = "GET_PARTIALFINANCER_FAILED";

export const GET_REJFINANCER_REQUEST = "GET_REJFINANCER_REQUEST";
export const GET_REJFINANCER_SUCCESS = "GET_REJFINANCER_SUCCESS";
export const GET_REJFINANCER_FAILED = "GET_REJFINANCER_FAILED";

export const GET_OVERDUEADMIN_REQUEST = "GET_OVERDUEADMIN_REQUEST";
export const GET_OVERDUEADMIN_SUCCESS = "GET_OVERDUEADMIN_SUCCESS";
export const GET_OVERDUEADMIN_FAILED = "GET_OVERDUEADMIN_FAILED";

export const PAID_STATUS_REQUEST = "PAID_STATUS_REQUEST";
export const PAID_STATUS_SUCCESS = "PAID_STATUS_SUCCESS";
export const PAID_STATUS_FAILED = "PAID_STATUS_FAILED";

export const REJECT_STATUS_REQUEST = "REJECT_STATUS_REQUEST";
export const REJECT_STATUS_SUCCESS = "REJECT_STATUS_SUCCESS";
export const REJECT_STATUS_FAILED = "REJECT_STATUS_FAILED";

export const FINANCER_PAYMENT_PENDING__REQUEST =
  "FINANCER_PAYMENT_PENDING__REQUEST";
export const FINANCER_PAYMENT_PENDING__SUCCESS =
  "FINANCER_PAYMENT_PENDING__SUCCESS";
export const FINANCER_PAYMENT_PENDING__FAILED =
  "FINANCER_PAYMENT_PENDING__FAILED";

export const FINANCER_PAYMENT_PARTIAL_REQUEST =
  "FINANCER_PAYMENT_PARTIAL_REQUEST";
export const FINANCER_PAYMENT_PARTIAL_SUCCESS =
  "FINANCER_PAYMENT_PARTIAL_SUCCESS";
export const FINANCER_PAYMENT_PARTIAL_FAILED =
  "FINANCER_PAYMENT_PARTIAL_FAILED";

export const FINANCER_PAID_REQUEST = "FINANCER_PAID_REQUEST";
export const FINANCER_PAID__SUCCESS = "FINANCER_PAID__SUCCESS";
export const FINANCER_PAID__FAILED = "FINANCER_PAID__FAILED";

export const REJECT_PAYMENT_PAID_REQUEST = "REJECT_PAYMENT_PAID_REQUEST";
export const REJECT_PAYMENT_PAID_SUCCESS = "REJECT_PAYMENT_PAID_SUCCESS";
export const REJECT_PAYMENT_PAID_FAILED = "REJECT_PAYMENT_PAID_FAILED";
