export const ADD_ADMIN_REQUEST = "ADD_ADMIN_REQUEST";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
export const ADD_ADMIN_FAILED = "ADD_ADMIN_FAILED"

export const EDIT_ADMIN_REQUEST = "EDIT_ADMIN_REQUEST"
export const EDIT_ADMIN_FAILED = "EDIT_ADMIN_FAILED"
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS"

export const DELETE_ADMIN_REQUEST = "DELETE_ADMIN_REQUEST"
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED"
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS"

export const GET_ADMIN_REQUEST = "GET_ADMIN_REQUEST";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED"