export const GET_VENDORLIST_REQUEST = "GET_VENDORLIST_REQUSET"
export const GET_VENDORLIST_SUCCESS = "GET_VENDORLIST_SUCCESS"
export const GET_VENDORLIST_FAILED = "GET_VENDORLIST_FAILED"

export const INVOICELISTLEN_REQUEST = "INVOICELISTLEN_REQUSET"
export const INVOICELISTLEN_SUCCESS = "INVOICELISTLEN_SUCCESS"
export const INVOICELISTLEN_FAILED = "INVOICELISTLEN_FAILED"

export const GET_INVOICELIST_REQUEST = "GET_INVOICELIST_REQUSET"
export const GET_INVOICELIST_SUCCESS = "GET_INVOICELIST_SUCCESS"
export const GET_INVOICELIST_FAILED = "GET_INVOICELIST_FAILED"

export const GET_FILTERAPPROVER_REQUEST = "GET_FILTERAPPROVER_REQUSET"
export const GET_FILTERAPPROVER_SUCCESS = "GET_FILTERAPPROVER_SUCCESS"
export const GET_FILTERAPPROVER_FAILED = "GET_FILTERAPPROVER_FAILED"


export const ADD_ACCEPTINV_REQUEST = "ADD_ACCEPTINV_REQUSET"
export const ADD_ACCEPTINV_SUCCESS = "ADD_ACCEPTINV_SUCCESS"
export const ADD_ACCEPTINV_FAILED = "ADD_ACCEPTINV_FAILED"

export const GET_APPROVER_VENREQUEST_REQUEST = "GET_VENREQUEST_REQUEST"
export const GET_APPROVER_VENREQUEST_SUCCESS = "GET_VENREQUEST_SUCCESS"
export const GET_APPROVER_VENREQUEST_FAILED = "GET_VENREQUEST_FAILED"

//status
export const GET_APPROVERSTATUS_REQUEST = "GET_APPROVERSTATUS_REQUSET"
export const GET_APPROVERSTATUS_SUCCESS = "GET_APPROVERSTATUS_SUCCESS"
export const GET_APPROVERSTATUS_FAILED = "GET_APPROVERSTATUS_FAILED"

export const GET_APPROVERAMOUNT_REQUEST = "GET_APPROVERAMOUNT_REQUSET"
export const GET_APPROVERAMOUNT_SUCCESS = "GET_APPROVERAMOUNT_SUCCESS"
export const GET_APPROVERAMOUNT_FAILED = "GET_APPROVERAMOUNT_FAILED"

//search
export const GET_APPROVERSEARCH_REQUEST = "GET_APPROVERSEARCH_REQUEST"
export const GET_APPROVERSEARCH_SUCCESS = "GET_APPROVERSEARCH_SUCCESS"
export const GET_APPROVERSEARCH_FAILED = "GET_APPROVERSEARCH_FAILED"

export const GET_INVOICEAPPROVERSEARCH_REQUEST = "GET_INVOICEAPPROVERSEARCH_REQUEST"
export const GET_INVOICEAPPROVERSEARCH_SUCCESS = "GET_INVOICEAPPROVERSEARCH_SUCCESS"
export const GET_INVOICEAPPROVERSEARCH_FAILED = "GET_INVOICEAPPROVERSEARCH_FAILED"

export const ADD_REJECTINV_REQUEST = "ADD_REJECTINV_REQUSET"
export const ADD_REJECTINV_SUCCESS = "ADD_REJECTINV_SUCCESS"
export const ADD_REJECTINV_FAILED = "ADD_REJECTINV_FAILED"

export const GET_APPUSER_VENDORREQUEST = "GET_APPUSER_VENDORREQUEST"
export const GET_APPUSER_VENDORSUCCESS = "GET_APPUSER_VENDORSUCCESS"
export const GET_APPUSER_VENDORFAILED = "GET_APPUSER_VENDORFAILED"


