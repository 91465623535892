export const ADD_VENDORUSER_REQUEST = "ADD_VENDORUSER_REQUEST"
export const ADD_VENDORUSER_SUCCESS = "ADD_VENDORUSER_SUCCESS"
export const ADD_VENDORUSER_FAILED = "ADD_VENDORUSER_FAILED"

export const GET_VENDORUSER_REQUEST = "GET_VENDORUSER_REQUEST"
export const GET_VENDORUSER_SUCCESS = "GET_VENDORUSER_SUCCESS"
export const GET_VENDORUSER_FAILED = "GET_VENDORUSER_FAILED"

export const EDIT_VENDORUSER_REQUEST = "EDIT_VENDORUSER_REQUEST"
export const EDIT_VENDORUSER_SUCCESS = "EDIT_VENDORUSER_SUCCESS"
export const EDIT_VENDORUSER_FAILED = "EDIT_VENDORUSER_FAILED"

export const DELETE_VENDORUSER_REQUEST = "DELETE_VENDORUSER_REQUEST"
export const DELETE_VENDORUSER_SUCCESS = "DELETE_VENDORUSER_SUCCESS"
export const DELETE_VENDORUSER_FAILED = "DELETE_VENDORUSER_FAILED"

//Vendor user dashboard
export const GET_INVBYUSER_REQUEST = "GET_INVBYUSER_REQUEST"
export const GET_INVBYUSER_SUCCESS = "GET_INVBYUSER_SUCCESS"
export const GET_INVBYUSER_FAILED = "GET_INVBYUSER_FAILED"

//SEARCH
export const GET_USERMNGSEARCH_REQUEST = "GET_USERMNGSEARCH_REQUEST"
export const GET_USERMNGSEARCH_SUCCESS = "GET_USERMNGSEARCH_SUCCESS"
export const GET_USERMNGSEARCH_FAILED = "GET_USERMNGSEARCH_FAILED"
