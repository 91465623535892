import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import { Row, Col } from "react-bootstrap";
import Deletelogo from "./images/delete.png";
import React from 'react';
import { contentmodify } from "./middleware/statusModify";

const ConfirmDeleteModal = ({id, title, close, handleDelete, open}) => {
    return (  
        <Modal open={open} onClose={close}>
        <Box className="modalclass deletebox">
          <Typography className="modal-title">
            <center> Delete {title && contentmodify(title)}</center>
            <div className="border-0 pt-2 del-hr">
              <hr className="mt-1 bottom_borderColor_overview"></hr>
            </div>
          </Typography>
          <div className="">
            <img
              src={`${Deletelogo}`}
              className="del-logo mt-3"
              alt="Delete logo"
            />
          </div>
          <Typography className="delete-message" variant="h6">
            Are you sure, you want to delete {title} ?
          </Typography>
          <Row className="del_row">
            <Col className="text-center">
              <button className="del-add" onClick={() => {
                handleDelete(id)
                close()
              }}>
                Delete
              </button>
            </Col>
            <Col className="text-center">
              <button className="del-cncl" onClick={close}>
                Cancel
              </button>
            </Col>
          </Row>
        </Box>
      </Modal>
    );
}
 
export default ConfirmDeleteModal;