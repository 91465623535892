import React, { lazy, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '../components/reusable/PrivateRoute'
import Loader from '../components/loader/Loader'

const VendorUser = lazy(() => import('../components/vendoruser/vendorUser'))
const CreateInvoice = lazy(() => import('../components/vendoruser/createInvoice'))
const UpdateInvoice = lazy(() => import('../components/vendoruser/updateInvoice'))
const UserProfile = lazy(() => import('../components/vendoruser/userProfile'))


const VendorUserRoutes = () => {
    return (
        <Suspense fallback={<Loader/>}>
        <Switch>
            <PrivateRoute exact path="/dashboard" component={VendorUser} />
            <PrivateRoute exact path='/vendor-user/create-invoice' component={CreateInvoice} />
            <PrivateRoute exact path='/vendor-user/edit-invoice/:id' component={UpdateInvoice} />
                <PrivateRoute exact path='/profile' component={UserProfile} />
        </Switch>
        </Suspense>
    )
}
export default VendorUserRoutes