import * as appType from "../constant/adminConstant/approverConstant";

export const addApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.ADD_APPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.ADD_APPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approverAdd: action.payload,
      };
    case appType.ADD_APPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const editApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.EDIT_APPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.EDIT_APPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approverEdit: action.payload,
      };
    case appType.EDIT_APPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approver: action.payload,
      };
    case appType.GET_APPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getApproverUpcomingOverdueReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_OVERDUE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_OVERDUE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        upcomingList: action.payload,
      };
    case appType.GET_APPROVER_OVERDUE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get Approver Pending Reducer
export const getApproverPendingReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_PENDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_PENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approvePending: action.payload,
      };
    case appType.GET_APPROVER_PENDING_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get Approver Paid Reducer
export const getApproverPaidReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_PAID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_PAID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approvePaid: action.payload,
      };
    case appType.GET_APPROVER_PAID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getApproverPartialPaidReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_PARTIAL_PAID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_PARTIAL_PAID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approvePartialPaid: action.payload,
      };
    case appType.GET_APPROVER_PARTIAL_PAID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get Approver Approved Reducer
export const getApproverApprovedReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_APPROVED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_APPROVED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approverApproved: action.payload,
      };
    case appType.GET_APPROVER_APPROVED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Get Approver Rejected Reducer
export const getApproverRejectedReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_REJECTED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_REJECTED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejected: action.payload,
      };
    case appType.GET_APPROVER_REJECTED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.DELETE_APPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.DELETE_APPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        approverDel: action.payload,
      };
    case appType.DELETE_APPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteApproverPanelReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.DELETE_APPROVER_PANEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.DELETE_APPROVER_PANEL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        delapprover: action.payload,
      };
    case appType.DELETE_APPROVER_PANEL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//approver specific all invoice
export const invApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_ACPINVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_ACPINVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        appInv: action.payload,
      };
    case appType.GET_ACPINVOICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
//approver specific all invoice pagination
export const invApproverLengthReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_ACPINVLEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_ACPINVLEN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        appInvLen: action.payload,
      };
    case appType.GET_ACPINVLEN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const acplistApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_ACPAPPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_ACPAPPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        acplistapp: action.payload,
      };
    case appType.GET_ACPAPPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const rejlistApproverReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_REJAPPROVER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_REJAPPROVER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejlistapp: action.payload,
      };
    case appType.GET_REJAPPROVER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getvenuserReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_VENUSER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_VENUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        venulist: action.payload,
      };
    case appType.GET_VENUSER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getvenIdReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_VENID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_VENID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        venId: action.payload,
      };
    case appType.GET_VENID_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Admin accept invoice
export const acceptInvReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.ACCEPT_INV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.ACCEPT_INV_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        acceptInv: action.payload,
      };
    case appType.ACCEPT_INV_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

//Admin reject invoice
export const rejectInvReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.REJECT_INV_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.REJECT_INV_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        rejectInv: action.payload,
      };
    case appType.REJECT_INV_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getApproverVendorRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case appType.GET_APPROVER_VENREQUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case appType.GET_APPROVER_VENREQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        getRequest: action.payload,
      };
    case appType.GET_APPROVER_VENREQUEST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
