import { toast } from "react-toastify";
import { getservice, putservice } from "../api/apiservices/service";
import * as types from "../constant/approverConstant/approverconstant";
import * as approverTypes from "../constant/adminConstant/approverConstant";
import {
  APP_DASH,
  APP_STATUS,
  INVFORAPP,
  APP_DASH_OVERVIEW,
  APP_OVERDUE_LIST,
  APP_PENDING_LIST,
  APP_PAID_LIST,
  APP_APPROVED_LIST,
  APP_REJECTED_LIST,
} from "../resources/APIEndpoints";

//Get approver dashboard
export const getApproverAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: types.GET_VENDORLIST_REQUEST });
    try {
      const response = await getservice(
        APP_DASH + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: types.GET_VENDORLIST_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.GET_VENDORLIST_FAILED, payload: error });
    }
  };

//Get approver dashboard upcoming
export const getApproverOverviewAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_REQUEST });
    try {
      const response = await getservice(
        APP_DASH_OVERVIEW +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({ type: approverTypes.GET_APPROVER_FAILED, payload: error });
    }
  };

//Get approver upcoming overdue
export const getApproverUpcomingOverdueAction =
  (search, sortBy, sortOrder, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_OVERDUE_REQUEST });
    try {
      const response = await getservice(
        APP_OVERDUE_LIST +
          `?search=${search}&sort=${sortBy}&orderby=${sortOrder}&from=${from}&to=${to}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_OVERDUE_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_OVERDUE_FAILED,
        payload: error,
      });
    }
  };

//Get Approver view pending List
export const getApproverPendingAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_PENDING_REQUEST });
    try {
      const response = await getservice(
        APP_PENDING_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_PENDING_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_PENDING_FAILED,
        payload: error,
      });
    }
  };

//Get Approver view Paid List
export const getApproverPaidAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_PAID_REQUEST });
    try {
      const response = await getservice(
        APP_PAID_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_PAID_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_PAID_FAILED,
        payload: error,
      });
    }
  };

export const getApproverPartialPaidAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_PARTIAL_PAID_REQUEST });
    try {
      const response = await getservice(
        APP_PAID_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_PARTIAL_PAID_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_PARTIAL_PAID_FAILED,
        payload: error,
      });
    }
  };

//Get Approver view Approved List
export const getApproverApprovedAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_APPROVED_REQUEST });
    try {
      const response = await getservice(
        APP_APPROVED_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_APPROVED_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_APPROVED_FAILED,
        payload: error,
      });
    }
  };

//Get Approver view Rejected List
export const getApproverRejectedAction =
  (id, search, from, to = "1", status) =>
  async (dispatch) => {
    dispatch({ type: approverTypes.GET_APPROVER_REJECTED_REQUEST });
    try {
      const response = await getservice(
        APP_REJECTED_LIST +
          `/${id}?search=${search}&from=${from}&to=${to}&status=${status}`
      );
      dispatch({
        type: approverTypes.GET_APPROVER_REJECTED_SUCCESS,
        payload: response.data,
      });
      toast.success(response.data.message);
    } catch (error) {
      dispatch({
        type: approverTypes.GET_APPROVER_REJECTED_FAILED,
        payload: error,
      });
    }
  };

//Get invoice for approver length
export const getInvoicelistlengthAction = () => async (dispatch) => {
  dispatch({ type: types.INVOICELISTLEN_REQUEST });
  try {
    const response = await getservice(INVFORAPP);
    dispatch({ type: types.INVOICELISTLEN_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: types.INVOICELISTLEN_FAILED, payload: error });
  }
};

//Get invoice for approver
export const getInvoicelistAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: types.GET_INVOICELIST_REQUEST });
    try {
      const response = await getservice(
        INVFORAPP + `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({ type: types.GET_INVOICELIST_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: types.GET_INVOICELIST_FAILED, payload: error });
    }
  };

//Accept Invoice
export const addAccpetinvAction = (invoiceno, status) => async (dispatch) => {
  dispatch({ type: types.ADD_ACCEPTINV_REQUEST });
  try {
    const response = await putservice(APP_STATUS + `/${invoiceno}`, status);
    dispatch({ type: types.ADD_ACCEPTINV_SUCCESS, payload: response.data });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: types.ADD_ACCEPTINV_FAILED, payload: error });
  }
};

// Reject Invoice
export const addRejectinvAction = (invoiceno, status) => async (dispatch) => {
  dispatch({ type: types.ADD_REJECTINV_REQUEST });
  try {
    const response = await putservice(APP_STATUS + `/${invoiceno}`, status);
    dispatch({ type: types.ADD_REJECTINV_SUCCESS, payload: response.data });
    toast.success(response.data.message);
  } catch (error) {
    dispatch({ type: types.ADD_REJECTINV_FAILED, payload: error });
  }
};

//Vendor Request
export const approverVendorRequestAction =
  (search, from, to = "1") =>
  async (dispatch) => {
    dispatch({ type: types.GET_APPROVER_VENREQUEST_REQUEST });
    try {
      const response = await getservice(
        "/api/approver/get-vendor-request" +
          `?search=${search}&from=${from}&to=${to}`
      );
      dispatch({
        type: types.GET_APPROVER_VENREQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({ type: types.GET_APPROVER_VENREQUEST_FAILED, payload: error });
    }
  };
