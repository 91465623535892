import React, { useEffect, useRef, useState } from "react";
import "../components/LoginPass.css";
import "../components/Login.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validateOtp } from "../action/userAction";
const OTPREG2 = /^[0-9]{6}/;
const OTPREG = /^[0-9]{1}$/;
const SubmitOtp = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  let addforgot = useSelector((state)=>state.addforgot)
  useEffect(()=>{

  },[addforgot])


  const [inputPass, setInputPass] = useState({
    ip1: "",
    ip2: "",
    ip3: "",
    ip4: "",
    ip5: "",
    ip6: "",
  });
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const handleKeyPress = (e, dom) => {
    const name = e.target.name;
    const value = e.target.value;

    if (
      OTPREG.test(e.target.value) ||
      e.target.value.length === 0 ||
      e.target.value.length < 1
    ) {
      setInputPass({ ...inputPass, [name]: value });
    } else if (OTPREG2.test(value)) {
      const newArr = value.split("");
      setInputPass({
        ip1: newArr[0],
        ip2: newArr[1],
        ip3: newArr[2],
        ip4: newArr[3],
        ip5: newArr[4],
        ip6: newArr[5],
      });
    } else {
      setError("Only numbers are allowed");
    }

    if (
      (OTPREG.test(e.target.value) &&
        Number(e.target.id) <= 7 &&
        // inputPass[name].length !== 0 &&
        // inputPass[name].length === 1
        e.target.value.length !== 0 &&
        e.target.value.length === 1) ||
      e.target.value.length === 2
    ) {
      document.getElementById(Number(e.target.id) + 1).focus();
    }
  };

  let passwordNew ;

  const chechError = () => {
    const pass = Object.values(inputPass).join("");
    if (pass.length < 6) {
      setError("Please file all the fields");
    }
    setPassword(pass);
    passwordNew = pass;
    return pass;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (chechError().length === 6) {
      const ValidatOtpRequestBody = {
        "referenceCode" : addforgot.referenceCode,
        "otp": Number(passwordNew),
      }
      // call api here
      dispatch(validateOtp(ValidatOtpRequestBody,history));

      // history.push("newpassword");
    }
  };

  useEffect(() => {
    document.getElementById("1").focus();
  }, []);
  return (
    <div className="background-img">
      <div className="forgot-outer">
        <div className="login-1">
          <div className="imagecol">
            <img
              src="https://nimapinfotech.com/wp-content/uploads/2019/01/nimap-logo.png"
              alt=""
              className="nimap-logo"
            />
          </div>
          <div className="invoicelabel">
            <label className="projectName">Invoice Management</label>
          </div>
          <div className="titlelabel">
            <label className="formTitle">Forgot Password</label>
          </div>
          <div className="otp">Please enter the one Time password</div>
          <div className="otp">to verify your account</div>
          <div className="email-text">
            the OTP has been sent to ********@gmail.com
          </div>
          <form className="forgotForm" onSubmit={handleSubmit}>
            <div className="inputOtp">
              {" "}
              <input
                name="ip1"
                className="pass"
                id="1"
                maxLength={6}
                value={inputPass.ip1}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("1"))
                }
                onFocus={() => setError("")}
              />{" "}
              <input
                className="pass"
                name="ip2"
                id="2"
                maxLength={6}
                value={inputPass.ip2}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("2"))
                }
                onFocus={() => setError("")}
              />{" "}
              <input
                className="pass"
                name="ip3"
                id="3"
                maxLength={6}
                value={inputPass.ip3}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("3"))
                }
                onFocus={() => setError("")}
              />{" "}
              <input
                className="pass"
                name="ip4"
                id="4"
                maxLength={6}
                value={inputPass.ip4}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("4"))
                }
                onFocus={() => setError("")}
              />{" "}
              <input
                className="pass"
                name="ip5"
                id="5"
                maxLength={6}
                value={inputPass.ip5}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("5"))
                }
                onFocus={() => setError("")}
              />{" "}
              <input
                className="pass"
                name="ip6"
                id="6"
                maxLength={6}
                value={inputPass.ip6}
                onChange={(e) =>
                  handleKeyPress(e, document.getElementById("6"))
                }
                onFocus={() => setError("")}
              />{" "}
            </div>

            <div
              style={{
                position: "relative",
                color: "red",
                fontSize: "15px",
                right: "105px",
              }}
            >
              {error}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                id="7"
                type="submit"
                className="otpbtn"
                // onClick={handlePassword}
              >
                Validate
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubmitOtp;
