export const ADD_APPROVER_REQUEST = "ADD_APPROVER_REQUEST";
export const ADD_APPROVER_SUCCESS = "ADD_APPROVER_SUCCESS";
export const ADD_APPROVER_FAILED = "ADD_APPROVER_FAILED";

export const EDIT_APPROVER_REQUEST = "EDIT_APPROVER_REQUEST";
export const EDIT_APPROVER_SUCCESS = "EDIT_APPROVER_SUCCESS";
export const EDIT_APPROVER_FAILED = "EDIT_APPROVER_FAILED";

export const GET_APPROVER_REQUEST = "GET_APPROVER_REQUEST";
export const GET_APPROVER_SUCCESS = "GET_APPROVER_SUCCESS";
export const GET_APPROVER_FAILED = "GET_APPROVER_FAILED";

export const GET_APPROVER_OVERDUE_REQUEST = "GET_APPROVER_OVERDUE_REQUEST";
export const GET_APPROVER_OVERDUE_SUCCESS = "GET_APPROVER_OVERDUE_SUCCESS";
export const GET_APPROVER_OVERDUE_FAILED = "GET_APPROVER_OVERDUE_FAILED";

export const GET_APPROVER_PENDING_REQUEST = "GET_APPROVER_PENDING_REQUEST";
export const GET_APPROVER_PENDING_SUCCESS = "GET_APPROVER_PENDING_SUCCESS";
export const GET_APPROVER_PENDING_FAILED = "GET_APPROVER_PENDING_FAILED";

export const GET_APPROVER_PAID_REQUEST = "GET_APPROVER_PAID_REQUEST";
export const GET_APPROVER_PAID_SUCCESS = "GET_APPROVER_PAID_SUCCESS";
export const GET_APPROVER_PAID_FAILED = "GET_APPROVER_PAID_FAILED";

export const GET_APPROVER_PARTIAL_PAID_REQUEST =
  "GET_APPROVER_PARTIAL_PAID_REQUEST";
export const GET_APPROVER_PARTIAL_PAID_SUCCESS =
  "GET_APPROVER_PARTIAL_PAID_SUCCESS";
export const GET_APPROVER_PARTIAL_PAID_FAILED =
  "GET_APPROVER_PARTIAL_PAID_FAILED";

export const GET_APPROVER_APPROVED_REQUEST = "GET_APPROVER_APPROVED_REQUEST";
export const GET_APPROVER_APPROVED_SUCCESS = "GET_APPROVER_APPROVED_SUCCESS";
export const GET_APPROVER_APPROVED_FAILED = "GET_APPROVER_APPROVED_FAILED";

export const GET_APPROVER_REJECTED_REQUEST = "GET_APPROVER_REJECTED_REQUEST";
export const GET_APPROVER_REJECTED_SUCCESS = "GET_APPROVER_REJECTED_SUCCESS";
export const GET_APPROVER_REJECTED_FAILED = "GET_APPROVER_REJECTED_FAILED";

export const DELETE_APPROVER_REQUEST = "DELETE_APPROVER_REQUEST";
export const DELETE_APPROVER_SUCCESS = "DELETE_APPROVER_SUCCESS";
export const DELETE_APPROVER_FAILED = "DELETE_APPROVER_FAILED";

export const DELETE_APPROVER_PANEL_REQUEST = "DELETE_APPROVER_PANEL_REQUEST";
export const DELETE_APPROVER_PANEL_SUCCESS = "DELETE_APPROVER_PANEL_SUCCESS";
export const DELETE_APPROVER_PANEL_FAILED = "DELETE_APPROVER_PANEL_FAILED";

export const GET_APPROVER_VENREQUEST_REQUEST = "GET_VENREQUEST_REQUEST";
export const GET_APPROVER_VENREQUEST_SUCCESS = "GET_VENREQUEST_SUCCESS";
export const GET_APPROVER_VENREQUEST_FAILED = "GET_VENREQUEST_FAILED";

//get approver invoice list
export const GET_ACPINVOICE_REQUEST = "GET_ACPINVOICE_REQUEST";
export const GET_ACPINVOICE_SUCCESS = "GET_ACPINVOICE_SUCCESS";
export const GET_ACPINVOICE_FAILED = "GET_ACPINVOICE_FAILED";

export const GET_ACPINVLEN_REQUEST = "GET_ACPINVLEN_REQUEST";
export const GET_ACPINVLEN_SUCCESS = "GET_ACPINVLEN_SUCCESS";
export const GET_ACPINVLEN_FAILED = "GET_ACPINVLEN_FAILED";

//get accepted list
export const GET_ACPAPPROVER_REQUEST = "GET_ACPAPPROVER_REQUEST";
export const GET_ACPAPPROVER_SUCCESS = "GET_ACPAPPROVER_SUCCESS";
export const GET_ACPAPPROVER_FAILED = "GET_ACPAPPROVER_FAILED";

//get rejected list
export const GET_REJAPPROVER_REQUEST = "GET_REJAPPROVER_REQUEST";
export const GET_REJAPPROVER_SUCCESS = "GET_REJAPPROVER_SUCCESS";
export const GET_REJAPPROVER_FAILED = "GET_REJAPPROVER_FAILED";

//Get vendor list in add approver modal
export const GET_VENUSER_REQUEST = "GET_VENUSER_REQUEST";
export const GET_VENUSER_SUCCESS = "GET_VENUSER_SUCCESS";
export const GET_VENUSER_FAILED = "GET_VENUSER_FAILED";

export const GET_VENID_REQUEST = "GET_VENID_REQUEST";
export const GET_VENID_SUCCESS = "GET_VENID_SUCCESS";
export const GET_VENID_FAILED = "GET_VENID_FAILED";

export const ACCEPT_INV_REQUEST = "ACCEPT_INV_REQUEST";
export const ACCEPT_INV_SUCCESS = "ACCEPT_INV_SUCCESS";
export const ACCEPT_INV_FAILED = "ACCEPT_INV_FAILED";

export const REJECT_INV_REQUEST = "REJECT_INV_REQUEST";
export const REJECT_INV_SUCCESS = "REJECT_INV_SUCCESS";
export const REJECT_INV_FAILED = "REJECT_INV_FAILED";
