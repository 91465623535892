export const GET_FINANCERS_REQUEST = "GET_FINANCERS_REQUEST";
export const GET_FINANCERS_SUCCESS = "GET_FINANCERS_SUCCESS";
export const GET_FINANCERS_FAILED = "GET_FINANCERS_FAILED";

export const GET_FINANCERS_OVERVIEW_REQUEST = "GET_FINANCERS_OVERVIEW_REQUEST";
export const GET_FINANCERS_OVERVIEW_SUCCESS = "GET_FINANCERS_OVERVIEW_SUCCESS";
export const GET_FINANCERS_OVERVIEW_FAILED = "GET_FINANCERS_OVERVIEW_FAILED";

export const GET_OVERDUE_REQUEST = "GET_OVERDUE_REQUEST";
export const GET_OVERDUE_SUCCESS = "GET_OVERDUE_SUCCESS";
export const GET_OVERDUE_FAILED = "GET_OVERDUE_FAILED";

export const GET_PAIDINV_REQUEST = "GET_PAIDINV_REQUEST";
export const GET_PAIDINV_SUCCESS = "GET_PAIDINV_SUCCESS";
export const GET_PAIDINV_FAILED = "GET_PAIDINV_FAILED";

export const GET_OVERDUEFILTER_REQUEST = "GET_OVERDUEFILTER_REQUEST";
export const GET_OVERDUEFILTER_SUCCESS = "GET_OVERDUEFILTER_SUCCESS";
export const GET_OVERDUEFILTER_FAILED = "GET_OVERDUEFILTER_FAILED";

export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILED = "UPDATE_STATUS_FAILED";

export const ADD_REJECT_REQUEST = "ADD_REJECT_REQUEST";
export const ADD_REJECT_SUCCESS = "ADD_REJECT_SUCCESS";
export const ADD_REJECT_FAILED = "ADD_REJECT_FAILED";

export const ARCHIVELIST_REQUEST = "ARCHIVELIST_REQUEST";
export const ARCHIVELIST_SUCCESS = "ARCHIVELIST_SUCCESS";
export const ARCHIVELIST_FAILED = "ARCHIVELIST_FAILED";

export const ARCHIVE_REQUEST = "ARCHIVE_REQUEST";
export const ARCHIVE_SUCCESS = "ARCHIVE_SUCCESS";
export const ARCHIVE_FAILED = "ARCHIVE_FAILED";

export const UNARCHIVE_REQUEST = "UNARCHIVE_REQUEST";
export const UNARCHIVE_SUCCESS = "UNARCHIVE_SUCCESS";
export const UNARCHIVE_FAILED = "UNARCHIVE_FAILED";

// Search
export const GET_SEARCHFIELD_REQUEST = "GET_SEARCHFIELD_REQUEST";
export const GET_SEARCHFIELD_SUCCESS = "GET_SEARCHFIELD_SUCCESS";
export const GET_SEARCHFIELD_FAILED = "GET_SEARCHFIELD_FAILED";

export const GET_DECSENDING_REQUEST = "GET_DECSENDING_REQUEST";
export const GET_DECSENDING_SUCCESS = "GET_DECSENDING_SUCCESS";
export const GET_DECSENDING_FAILED = "GET_DECSENDING_FAILED";

export const GET_OVERDUESEARCHFIELD_REQUEST = "GET_OVERDUESEARCHFIELD_REQUEST";
export const GET_OVERDUESEARCHFIELD_SUCCESS = "GET_OVERDUESEARCHFIELD_SUCCESS";
export const GET_OVEDUESEARCHFAILED_FAILED = "GET_OVERDUESEARCHFIELD_FAILED";

//sorting
export const GET_ACCENDING_REQUEST = "GET_ACCENDING_REQUEST";
export const GET_ACCENDING_SUCCESS = "GET_ACCENDING_SUCCESS";
export const GET_ACCENDING_FAILED = "GET_ACCENDING_FAILED";

export const GET_DATEACCENDING_REQUEST = "GET_DATEACCENDING_REQUEST";
export const GET_DATEACCENDING_SUCESS = "GET_DATEACCENDING_SUCCESS";
export const GET_DATEACCENDING_FAILED = "GET_DATEACCENDING_FAILED";

export const GET_DATEDECSENDING_REQUEST = "GET_DATEDECSENDING_REQUEST";
export const GET_DATEDECSENDING_SUCESS = "GET_DATEDECSENDING_SUCESS";
export const GET_DATEDECSENDING_FAILED = "GET_DATEDECSENDING_FAILED";

export const GET_DUEDATEACCENDING_REQUEST = "GET_DUEDATEACCENDING_REQUEST";
export const GET_DUEDATEACCENDING_SUCESS = "GET_DUEDATEACCENDING_SUCESS";
export const GET_DUEDATEACCENDING_FAILED = "GET_DUEDATEACCENDING_FAILED";

//Paid Tab sort
export const DATE_REQUEST = "DATE_REQUEST";
export const DATE_SUCESS = "DATE_SUCESS";
export const DATE_FAILED = "DATE_FAILED";

export const DUE_DATE_REQUEST = "DUE_DATE_REQUEST";
export const DUE_DATE_SUCESS = "DUE_DATE_SUCESS";
export const DUE_DATE_FAILED = "DUE_DATE_FAILED";

export const AMOUNT_REQUEST = "AMOUNT_REQUEST";
export const AMOUNT_SUCESS = "AMOUNT_SUCESS";
export const AMOUNT_FAILED = "AMOUNT_FAILED";

export const BULK_PAYMENT_INVOICE_LIST = "BULK_PAYMENT_INVOICE_LIST"
export const REMOVE_FROM_BULK_PAYMENT_INVOICE_LIST = "REMOVE_FROM_BULK_PAYMENT_INVOICE_LIST"
export const EMPTY_SELECETED_INVOICE_LIST = "EMPTY_SELECETED_INVOICE_LIST"
export const UPDATE_FORMIK_DATA = "UPDATE_FORMIK_DATA"
export const CLEAR_FORMIK_DATA = "CLEAR_FORMIK_DATA"
export const REMOVE_FROM_FORMIK_DATA = "REMOVE_FROM_FORMIK_DATA"
