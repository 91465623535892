import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./store";
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';


ReactDOM.render(
    <GoogleOAuthProvider 
    clientId="334809510481-jqlq8hrnacsejgjn5v62jc3sii4vrh74.apps.googleusercontent.com">
  <Provider store={store}>
  <BrowserRouter>

    <App />
    </BrowserRouter>
    </Provider>
    </GoogleOAuthProvider>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
////////////////////

///////////

