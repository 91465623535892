import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "@material-ui/core/Modal";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Box from "@material-ui/core/Box";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import { privateRequest } from "../../api/apiconfig/privateRequest";
import { addCommasToNumber, removeCommasConvertToNumber } from "../../utility";
import { ADMIN_PAID, BASE_URL, INV_LIST } from "../../resources/APIEndpoints";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import * as financeType from "../../constant/adminConstant/financerConstant";
import "./admin.css";
function PartialPaymentModal({ open, close,data}) {
  let datat=[
    {
      invoice_no:"NMP13885745",
      date:"1-05-2023",
      amount:100,
      pending_amount:5000,
      partial_amount:2000,
      GST_Amount:10,
      TDS_Amount:10,
      totalAmount:80000,
      reason:"not sufficient money"

    },{
      invoice_no:"NMP13885745",
      date:"1-05-2023",
      amount:100,
      pending_amount:5000,
      partial_amount:1000,
      GST_Amount:10,
      TDS_Amount:10,
      totalAmount:80000,
      reason:"easy to installment"

    },
    {
      invoice_no:"NMP13885745",
      date:"1-05-2023",
      amount:100,
      pending_amount:5000,
      partial_amount:3000,
      GST_Amount:10,
      TDS_Amount:10,
      totalAmount:80000,
      reason:"pay step by step"

    },
    {
      invoice_no:"NMP13885745",
      date:"1-05-2023",
      amount:100,
      pending_amount:5000,
      partial_amount:4000,
      GST_Amount:10,
      TDS_Amount:10,
      totalAmount:80000,
      reason:"easy to installment"
    },
    {
      invoice_no:"NMP13885745",
      date:"1-05-2023",
      amount:100,
      pending_amount:5000,
      partial_amount:3000,
      GST_Amount:10,
      TDS_Amount:10,
      totalAmount:80000,
      reason:"not sufficient money"
    }

  ]
  return (
    <>
      <div className="modals-content">
        <Modal open={open} onClose={close}>
          <Box className="modalclass adminVenBox">

         <div 
         className="btnparttext"
        //  style={{display:"flex",justifyContent:"space-between"}}
         >
         <Typography className="modal-title">
              Partial Payment history
              <div className="border-0 pt-1">
                <hr className="mt-1 bottom_borderColor_overview"></hr>
              </div>
            </Typography>
            <button 
            
            // style={{height:"30px"}} 
            className="close-btn closeinpartialPayModal" onClick={close}>
              <CloseIcon />
            </button>
         </div>
            <Formik
            >
              {({ errors, touched, setFieldValue, values }) => (
                <Form>
                  <Row className="form_row">
                    <Col>
                    <table className="table-responsive">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Date</th>
                    <th className="align_center">Amount(&#8377;)</th>
                    <th className="align_center">Pending Amount(&#8377;)</th>
                    <th className="align_center">Partial Amount(&#8377;)</th>
                    <th className="align_center">GST Amt.(&#8377;)</th>
                    <th className="align_center">TDS Amt.(&#8377;)</th>
                    <th className="align_center">Total Amt.(&#8377;)</th>
                    <th>Reason</th>
                 
                 
                  </tr>
                </thead>
                <tbody>
                  {datat.map((data)=>(
                     <tr>
                     <td><span>{data.invoice_no}</span></td>
                     <td><span>{data.date}</span></td>
                     <td><span>{data.amount}</span></td>
                     <td><span>{data?.pending_amount || "-"}</span></td>
                     <td><span>{data?.partial_amount || "-"}</span></td>
                     <td><span>{data.GST_Amount}</span></td>
                     <td><span>{data.TDS_Amount}</span></td>
                     <td><span>{data.totalAmount}</span></td>
                     <td><span>{data.reason}</span></td>
  
                    </tr>
                  ) )}

   
                 

               
                </tbody>
                </table>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default PartialPaymentModal;
