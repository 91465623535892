import * as admincount from "../../constant/adminConstant/adminCount";
import * as role from "../../constant/adminConstant/roleConstant";


export const getAdminFinacestatus = (state = {}, action) => {
    switch (action.type) {
        case role.GET_AFSTATUS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case role.GET_AFSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getafStatus: action.payload
            }
        case role.GET_AFSTATUS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getAdminFinaceAmount = (state = {}, action) => {
    switch (action.type) {
        case role.GET_AFAMOUNT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case role.GET_AFAMOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getafAmount: action.payload
            }
        case role.GET_AFAMOUNT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getOrganization = (state = {}, action) => {
    switch (action.type) {
        case role.GET_ADMINORG_REQUEST:
            return {
                ...state,
                loading: true
            }
        case role.GET_ADMINORG_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getorg: action.payload
            }
        case role.GET_ADMINORG_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getDepartment = (state = {}, action) => {
    switch (action.type) {
        case role.GET_ADMINDEPT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case role.GET_ADMINDEPT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getdept: action.payload
            }
        case role.GET_ADMINDEPT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const getCountAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.COUNT_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.COUNT_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getCountF: action.payload
            }
        case admincount.COUNT_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

//PENDING FOR PAYMENT

export const getPendingPayAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.PENDINGPAY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.PENDINGPAY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getPendingP: action.payload
            }
        case admincount.PENDINGPAY_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

//Review Pending

export const getReviewPenAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.REVIEWPEN_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.REVIEWPEN_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getReviewP: action.payload
            }
        case admincount.REVIEWPEN_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


//Reject Count


export const getRejAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.REJECTCOUNT_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.REJECTCOUNT_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getReJC: action.payload
            }
        case admincount.REJECTCOUNT_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


//Paid Count
export const getPaidCountAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.PAIDCOUNT_ADMIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.PAIDCOUNT_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getPaidCount: action.payload
            }
        case admincount.PAIDCOUNT_ADMIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

//All Vendor Count
export const getVendorCountAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINVENDOR_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINVENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getVendorcount: action.payload
            }
        case admincount.ADMINVENDOR_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}
//All Approver Count
export const getApproverCountAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINAPPROVER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINAPPROVER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getAppcount: action.payload
            }
        case admincount.ADMINAPPROVER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

//All Financer Count
export const getFinancerCountAdmin = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINFINANCER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINFINANCER_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                getFincount: action.payload
            }
        case admincount.ADMINFINANCER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const getadminListReducer = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINLIST_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINLIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                adminlist: action.payload
            }
        case admincount.ADMINLIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}


export const adminacceptReducer = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINACCEPT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINACCEPT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                adminaccept: action.payload
            }
        case admincount.ADMINACCEPT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const adminpaidReducer = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINPAID_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINPAID_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                adminpaid: action.payload
            }
        case admincount.ADMINPAID_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const adminrejectReducer = (state = {}, action) => {
    switch (action.type) {
        case admincount.ADMINREJECT_REQUEST:
            return {
                ...state,
                loading: true
            }
        case admincount.ADMINREJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                adminreject: action.payload
            }
        case admincount.ADMINREJECT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}