import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "../components/reusable/PublicRoute";
import PrivateRoute from "../components/reusable/PrivateRoute";
import Loader from "../components/loader/Loader";

const Login = lazy(() => import("../components/Login"));
const FinanceDashboard = lazy(() =>
  import("../components/finance/financeDashboard")
);
const Invoice = lazy(() => import("../components/finance/overdueDate"));

const BulkInvoiceProcess = lazy(() => import("../components/finance/BulkInvoiceProcess"));

const PaidInvoices = lazy(() => import("../components/finance/paidInvoice"));
const FinanceProfile = lazy(() =>
  import("../components/finance/financeProfile")
);
const ArchivedInvoices = lazy(() =>
  import("../components/finance/archivedInvoices")
);
const ArchivedFinancerBulkPayment = lazy(() =>
  import("../components/finance/ArchivedFinancerBulkPayment")
);
const FinanceUpcomingOverdue = lazy(() =>
  import("../components/finance/FinanceUpcomingOverdue")
);
const VendorListUser = lazy(() =>
  import("../components/finance/VendorListUser")
);
const ProceedInvoioce = lazy(()=>
  import("../components/finance/ProceedInvoioce")
);

const VendorList = lazy(() => import("../components/finance/VendorList"));
const FinancerBankData = lazy(() => import("../components/finance/financerBankData"));

const FinancerRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute exact path="/dashboard" component={FinanceDashboard} />
        <PrivateRoute exact path="/financer/invoice" component={Invoice} />
        <PrivateRoute exact path="/financer/invoice/proceed" component={ProceedInvoioce} />
        <PrivateRoute
          exact
          path="/financer/paid-invoice"
          component={PaidInvoices}
        />
        <PrivateRoute
          exact
          path="/archived-invoice"
          component={ArchivedInvoices}
        />
         <PrivateRoute
          exact
          path="/financer/bulk-upload/archived-invoice"
          component={ArchivedFinancerBulkPayment}
        />
        <PrivateRoute
          exact
          path="/finance/upcoming-overdue"
          component={FinanceUpcomingOverdue}
        />
        <PrivateRoute exact path="/finance/vendorlist" component={VendorList} />
        <PrivateRoute
          exact
          path="/finance/vendorlistuser/:id"
          component={VendorListUser}
        />
         <PrivateRoute exact 
         path="/finance/bulk-invoice-process"
         component={BulkInvoiceProcess} />
        
        <PrivateRoute exact 
         path="/finance/bank-data"
         component={FinancerBankData} 
        />

        <PrivateRoute exact path="/profile" component={FinanceProfile} />
        <PublicRoute exact path="/" component={Login} />
      </Switch>
    </Suspense>
  );
};
export default FinancerRoutes;
