export const ADD_ROLE_REQUEST = "ADD_ROLE_REQUEST"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"
export const ADD_ROLE_FAILED = "ADD_ROLE_FAILED"

export const GET_ROLELIST_REQUEST = "GET_ROLELIST_REQUEST"
export const GET_ROLELIST_SUCCESS = "GET_ROLELIST_SUCCESS"
export const GET_ROLELIST_FAILED = "GET_ROLELIST_FAILED"

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_FAILED = "GET_ROLE_FAILED"

export const EDIT_ROLE_REQUEST = "EDIT_ROLE_REQUEST"
export const EDIT_ROLE_SUCCESS = "EDIT_ROLE_SUCCESS"
export const EDIT_ROLE_FAILED = "EDIT_ROLE_FAILED"

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"
export const DELETE_ROLE_FAILED = "DELETE_ROLE_FAILED"

//Admin Status
export const GET_INVOICESTATUS_REQUEST = "GET_INVOICESTATUS_REQUEST"
export const GET_INVOICESTATUS_SUCCESS = "GET_INVOICESTATUS_SUCCESS"
export const GET_INVOICESTATUS_FAILED = "GET_INVOICESTATUS_FAILED"

export const GET_SECONDSTATUS_REQUEST = "GET_SECONDSTATUS_REQUEST"
export const GET_SECONDSTATUS_SUCCESS = "GET_SECONDSTATUS_SUCCESS"
export const GET_SECONDSTATUS_FAILED = "GET_SECONDSTATUS_FAILED"

export const GET_THIRDSTATUS_REQUEST = "GET_THIRDSTATUS_REQUEST"
export const GET_THIRDSTATUS_SUCCESS = "GET_THIRDSTATUS_SUCCESS"
export const GET_THIRDSTATUS_FAILED = "GET_THIRDSTATUS_FAILED"

//Admin Dashboard 

//Finanacer and Admin status
export const GET_AFSTATUS_REQUEST = "GET_AFSTATUS_REQUEST"
export const GET_AFSTATUS_SUCCESS = "GET_AFSTATUS_SUCCESS"
export const GET_AFSTATUS_FAILED = "GET_AFSTATUS_FAILED"

export const GET_AFAMOUNT_REQUEST = "GET_AFAMOUNT_REQUEST"
export const GET_AFAMOUNT_SUCCESS = "GET_AFAMOUNT_SUCCESS"
export const GET_AFAMOUNT_FAILED = "GET_AFAMOUNT_FAILED"

//Organization Table
export const GET_ADMINORG_REQUEST = "GET_ADMINORG_REQUEST"
export const GET_ADMINORG_SUCCESS = "GET_ADMINORG_SUCCESS"
export const GET_ADMINORG_FAILED = "GET_ADMINORG_FAILED"

//Department Table
export const GET_ADMINDEPT_REQUEST = "GET_ADMINDEPT_REQUEST"
export const GET_ADMINDEPT_SUCCESS = "GET_ADMINDEPT_SUCCESS"
export const GET_ADMINDEPT_FAILED = "GET_ADMINDEPT_FAILED"