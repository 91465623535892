import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { approverVendorRequestAction } from "../../action/approverAction";

import {
  getapproveuserAction,
  rejectVenRequestAction,
  vendorRequestAction,
} from "../../action/adminAction";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  styled,
  makeStyles,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Collapse } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InvoiceLogo from "../images/Nimapmng.png";

import { ReactComponent as Dashboard } from "../images/dashboard.svg";
import { ReactComponent as Admin } from "../images/admin.svg";
import { ReactComponent as Vendor } from "../images/vendor.svg";
import { ReactComponent as Approver } from "../images/approver.svg";
import { ReactComponent as Financer } from "../images/financer.svg";
import { ReactComponent as Archive } from "../images/archive_icon.svg";
import { ReactComponent as Setting } from "../images/settings.svg";
import "./sidebar.css";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#f7fcff",
      color: "black !important",
    },
    color: "black !important",
  },
})(MuiListItem);

function Adminsidebars(props) {
  const dispatch = useDispatch();
  const { getRequest } = useSelector((state) => state.venRequest);
  const { acceptReq } = useSelector((state) => state.accRequest);
  const { rejectReq } = useSelector((state) => state.rejRequst);
  const { appuserlist } = useSelector((state) => state.getauser);
  const { stylesname } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [vendorSub, setVendorSub] = useState(false);
  const [approverSub, setApproverSub] = useState(false);
  const [financerSub, setFinancerSub] = useState(false);
  const [settingSub, setSettingSub] = useState(false);
  const [invoiceSub, setInvoiceSub] = useState(false);

  const theme = useTheme();

  const admin = "admin";

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      color: "#f7fcff",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: { drawerWidth },
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth})`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      minHeight: 104,
      padding: theme.spacing(2),
      [theme.breakpoints.between("xs", "sm")]: {
        minHeight: 50,
        padding: theme.spacing(1.5),
      },
      [theme.breakpoints.between("sm", "md")]: {
        minHeight: 50,
        padding: theme.spacing(3),
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.down("xs", "sm")]: {
        width: "193px",
        fontSize: "16px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        width: "220px",
        fontSize: "17px",
      },
      fontSize: "18px",
      transition: "0.2s",
    },
    sublist: {
      marginLeft: "60px",
      fontSize: "17px",
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "14px",
        marginLeft: "50px",
      },
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "16px",
        marginLeft: "53px",
      },
    },
    sublistItem: {
      padding: "0px",
      cursor: "pointer",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    Listicon: {
      minWidth: "35px",
      color: "#ccebf1",
      fontSize: "25px",
    },
    ListItem: {
      paddingBottom: "0px",
      paddingLeft: "25px",
      color: "black",
      [theme.breakpoints.between("xs", "sm")]: {
        paddingLeft: "16px",
      },
    },
  }));
  const classes = useStyles();

  const onVendor = () => {
    setVendorSub(!vendorSub);
    setApproverSub(false);
    setFinancerSub(false);
    setSettingSub(false);
    setInvoiceSub(false);
  };

  const onInvoiceMaster = () => {
    setInvoiceSub(!invoiceSub);
    setApproverSub(false);
    setFinancerSub(false);
    setVendorSub(false);
    setSettingSub(false);
  };

  const onApprover = () => {
    setApproverSub(!approverSub);
    setFinancerSub(false);
    setVendorSub(false);
    setSettingSub(false);
    setInvoiceSub(false);
  };

  const onFinancer = () => {
    setFinancerSub(!financerSub);
    setApproverSub(false);
    setVendorSub(false);
    setSettingSub(false);
    setInvoiceSub(false);
  };

  const onSetting = () => {
    setSettingSub(!settingSub);
    setApproverSub(false);
    setFinancerSub(false);
    setVendorSub(false);
    setInvoiceSub(false);
  };
  useEffect(() => {
    dispatch(approverVendorRequestAction("", 1, 10));
  }, []);

  const drawer = (
    <div className="drawer">
      <div className={classes.toolbar}>
        <List>
          <ListItem>
            <img
              src={`${InvoiceLogo}`}
              className="nimaplogo"
              alt="Company_Logo"
            />
            <ArrowBackIosIcon fontSize="large" onClick={props.toggle} />
          </ListItem>
        </List>
      </div>
      <List>
        <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/dashboard"
        >
          <ListItemIcon className={classes.Listicon}>
            <Dashboard />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Dashboard
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      <List>
        <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/admin"
        >
          <ListItemIcon className={classes.Listicon}>
            <Admin />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Admin
            </Typography>
          </ListItemText>
        </ListItem>
      </List>

      {/* ----------------------------------------------- */}
      <List>
        <ListItem className={classes.ListItem} onClick={onInvoiceMaster}>
          <ListItemIcon className={classes.Listicon}>
            <Vendor className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Invoice
            </Typography>
          </ListItemText>
          {invoiceSub ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={invoiceSub} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/invoicetrails"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">Invoices</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/archived-invoice"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">Archived Invoices</Typography>
              </ListItemText>
              {/* <span className="admin-notification">{getRequest?.data?.count || 0}</span> */}
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/upcoming-overdue"
            >
              <ListItemText className={classes.sublist}>
                {/* <Typography variant="inherit">Create Invoice</Typography> */}
                <Typography variant="inherit">Upcoming Overdue</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>

      {/* ------------------------------------------------- */}

      {/* <List>
        <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/invoicetrails"
        >
          <ListItemIcon className={classes.Listicon}>
            <Archive className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
            Invoices
            </Typography>
          </ListItemText>
        </ListItem>
      </List> */}

      <List>
        <ListItem className={classes.ListItem} onClick={onVendor}>
          <ListItemIcon className={classes.Listicon}>
            <Vendor className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Vendor
            </Typography>
          </ListItemText>
          {vendorSub ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={vendorSub} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/vendor"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">View All Vendor</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/vendor-request"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit"> Vendor Request</Typography>
              </ListItemText>
              <span className="admin-notification">
                {getRequest?.data?.count || 0}
              </span>
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/create-invoice"
            >
              <ListItemText className={classes.sublist}>
                {/* <Typography variant="inherit">Create Invoice</Typography> */}
                <Typography variant="inherit">Upload Invoice</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>

      <List>
        <ListItem className={classes.ListItem} onClick={onFinancer}>
          <ListItemIcon className={classes.Listicon}>
            <Financer className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Financer
            </Typography>
          </ListItemText>
          {financerSub ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={financerSub} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #0dcaf0",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/financeAdmin"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">View All Financer</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/bank-data"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit"> Bank Data</Typography>
              </ListItemText>
            </ListItem>
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/bulk-upload/archived-invoice"
            >
              {/* <ListItemIcon className={classes.Listicon}>
                <Archive className="side-menulist" />
              </ListItemIcon> */}
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">Archived Bulk Payment</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>

      <List>
        <ListItem className={classes.ListItem} onClick={onApprover}>
          <ListItemIcon className={classes.Listicon}>
            <Approver className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Approver
            </Typography>
          </ListItemText>
          {approverSub ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={approverSub} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              to="/admin/approver"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">View All Approver</Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Collapse>
      </List>

      {/* <List>
        <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/admin/archived-invoice"
        >
          <ListItemIcon className={classes.Listicon}>
            <Archive className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Archived Invoices
            </Typography>
          </ListItemText>
        </ListItem>
      </List> */}

      <List>
        {/* <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/admin/bulk-upload/archived-invoice"
        >
          <ListItemIcon className={classes.Listicon}>
            <Archive className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Archived Bulk Payment
            </Typography>
          </ListItemText>
        </ListItem> */}
      </List>

      <List>
        <ListItem className={classes.ListItem} onClick={onSetting}>
          <ListItemIcon className={classes.Listicon}>
            <Setting className="side-menulist" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Setting
            </Typography>
          </ListItemText>
          {settingSub ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={settingSub} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #0dcaf0",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/users"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit">Roles & Permission</Typography>
              </ListItemText>
            </ListItem>
            {/* <ListItem
              className={classes.sublistItem}
              component={NavLink}
              activeStyle={{
                borderRight: "5px solid #00a8ff",
                borderRadius: "5px",
                color: "black",
                fontWeight: "bold",
              }}
              exact
              to="/admin/bank-data"
            >
              <ListItemText className={classes.sublist}>
                <Typography variant="inherit"> Bank Data</Typography>
              </ListItemText>
            </ListItem> */}
          </List>
        </Collapse>
      </List>

      {/* <List>
        <ListItem
          className={classes.ListItem}
          component={NavLink}
          activeStyle={{
            borderRight: "6px solid #00a8ff",
            borderRadius: "5px",
            color: "black",
            fontWeight: "bold",
          }}
          exact
          to="/admin/upcoming-overdue"
        >
          <ListItemIcon className={classes.Listicon}>
            <Dashboard />
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography variant="inherit" className={classes.drawerPaper}>
              Upcoming Overdue
            </Typography>
          </ListItemText>
        </ListItem>
      </List> */}
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <Main className="navMain">
        <CssBaseline />
        <nav
          className={`${stylesname} ${classes.drawer}`}
          aria-label="mailbox folders"
          style={{ zIndex: 0 }}
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              // variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              onClose={handleDrawerToggle}
              variant="persistent"
              open={props.mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="persistent"
              anchor="left"
              open={props.mobileOpen}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </Main>
    </div>
  );
}

export default Adminsidebars;
