import React from "react";
import background from './images/Login.png';
import "../components/LoginPass.css";

export default function PasswordChange() {
    return(
        <div className="background img " style={{ backgroundImage: `url(${background})` }}>
      <div className="login-1 col-md-8">
        <img
          src="https://nimapinfotech.com/wp-content/uploads/2019/01/nimap-logo.png"
          alt=""
        />
         <div>
              <h3 className="reset-text">Password reset link sent you on your Email!</h3>
          </div>
      </div>
      </div>
    )
    ;
    // <h2>Password Change Successfully!</h2>
}