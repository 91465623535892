import React from 'react'
import { useSelector } from 'react-redux'
import {Route, Redirect} from 'react-router-dom'

const PublicRoute = ({component:Component,...rest}) =>{
    const user_Data = JSON.parse(localStorage.getItem("isLoggedIn"))
    const {isAuth} = useSelector(state=>state?.signin)

return(
<Route
        {...rest}
        render={props=>
            isAuth==true ?
            <Redirect to="/dashboard"/>:
            <Component {...props}/>
        }
    />
    
   )
}


export default PublicRoute
