export const COUNT_ADMIN_REQUEST = " COUNT_ADMIN_REQUEST"
export const COUNT_ADMIN_SUCCESS = "COUNT_ADMIN_SUCCESS"
export const COUNT_ADMIN_FAILED = "COUNT_ADMIN_FAILED"


export const PENDINGPAY_ADMIN_REQUEST = "PENDINGPAY_ADMIN_REQUEST"
export const PENDINGPAY_ADMIN_SUCCESS = "PENDINGPAY_ADMIN_SUCCESS"
export const PENDINGPAY_ADMIN_FAILED = "PENDINGPAY_ADMIN_FAILED"

export const REVIEWPEN_ADMIN_REQUEST = "REVIEWPEN_ADMIN_REQUEST"
export const REVIEWPEN_ADMIN_SUCCESS = "REVIEWPEN_ADMIN_SUCCESS"
export const REVIEWPEN_ADMIN_FAILED = "REVIEWPEN_ADMIN_FAILED"

export const REJECTCOUNT_ADMIN_REQUEST = "REJECTCOUNT_ADMIN_REQUEST"
export const REJECTCOUNT_ADMIN_SUCCESS = "REJECTCOUNT_ADMIN_SUCCESS"
export const REJECTCOUNT_ADMIN_FAILED = "REJECTCOUNT_ADMIN_FAILED"

export const PAIDCOUNT_ADMIN_REQUEST = "PAIDCOUNT_ADMIN_REQUEST"
export const PAIDCOUNT_ADMIN_SUCCESS = "PAIDCOUNT_ADMIN_SUCCESS"
export const PAIDCOUNT_ADMIN_FAILED = "PAIDCOUNT_ADMIN_FAILED"

export const ADMINVENDOR_REQUEST = "ADMINVENDOR_REQUEST"
export const ADMINVENDOR_SUCCESS = "ADMINVENDOR_SUCCESS"
export const ADMINVENDOR_FAILED = "ADMINVENDOR_FAILED"

export const ADMINFINANCER_REQUEST = "ADMINFINANCER_REQUEST"
export const ADMINFINANCER_SUCCESS = "ADMINFINANCER_SUCCESS"
export const ADMINFINANCER_FAILED = "ADMINFINANCER_FAILED"

export const ADMINAPPROVER_REQUEST = "ADMINAPPROVER_REQUEST"
export const ADMINAPPROVER_SUCCESS = "ADMINAPPROVER_SUCCESS"
export const ADMINAPPROVER_FAILED = "ADMINAPPROVER_FAILED"

export const ADMINLIST_REQUEST = "ADMINLIST_REQUEST"
export const ADMINLIST_SUCCESS = "ADMINLIST_SUCCESS"
export const ADMINLIST_FAILED = "ADMINLIST_FAILED"

export const ADMINACCEPT_REQUEST = "ADMINACCEPT_REQUEST"
export const ADMINACCEPT_SUCCESS = "ADMINACCEPT_SUCCESS"
export const ADMINACCEPT_FAILED = "ADMINACCEPT_FAILED"

export const ADMINPAID_REQUEST = "ADMINPAID_REQUEST"
export const ADMINPAID_SUCCESS = "ADMINPAID_SUCCESS"
export const ADMINPAID_FAILED = "ADMINPAID_FAILED"

export const ADMINREJECT_REQUEST = "ADMINREJECT_REQUEST"
export const ADMINREJECT_SUCCESS = "ADMINREJECT_SUCCESS"
export const ADMINREJECT_FAILED = "ADMINREJECT_FAILED"