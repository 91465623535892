//Vendor Dashboard
export const GET_VENDORS_REQUEST = "GET_VENDORS_REQUEST";
export const GET_VENDORS_SUCCESS = "GET_VENDORS_SUCCESS";
export const GET_VENDORS_FAILED = "GET_VENDORS_FAILED";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const GET_PROFILE_PER_USER_REQUEST = "GET_PROFILE_PER_USER_REQUEST";
export const GET_PROFILE_PER_USER_SUCCESS = "GET_PROFILE_PER_USER_SUCCESS";
export const GET_PROFILE_PER_USER_FAILED = "GET_PROFILE_PER_USER_FAILED";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";

export const GET_VIEWINV_REQUEST = "GET_VIEWINV_REQUEST";
export const GET_VIEWINV_SUCCESS = "GET_VIEWINV_SUCCESS";
export const GET_VIEWINV_FAILED = "GET_VIEWINV_FAILED";

export const GET_VENDORSEARCH_REQUEST = "GET_VENDORSEARCH_REQUEST";
export const GET_VENDORSEARCH_SUCCESS = "GET_VENDORSEARCH_SUCCESS";
export const GET_VENDORSEARCH_FAILED = "GET_VENDORSEARCH_FAILED";

// vendor status
export const GET_VENDORSTATUS_REQUEST = "GET_VENDORSTATUS_REQUEST";
export const GET_VENDORSTATUS_SUCCESS = "GET_VENDORSTATUS_SUCCESS";
export const GET_VENDORSTATUS_FAILED = "GET_VENDORSTATUS_FAILED";

export const GET_VENDORAMOUNT_REQUEST = "GET_VENDORAMOUNT_REQUEST";
export const GET_VENDORAMOUNT_SUCCESS = "GET_VENDORAMOUNT_SUCCESS";
export const GET_VENDORAMOUNT_FAILED = "GET_VENDORAMOUNT_FAILED";
